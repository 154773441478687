import React, {useState, useContext, useCallback, useEffect} from 'react';
import { withRouter, Redirect } from "react-router";
import {Link} from 'react-router-dom';
import { Modal, Form, Button, Row, Col, closeButton} from 'react-bootstrap';
import Popup from 'reactjs-popup';
import '../css/PodsComponents.css'
import { AuthContext } from "../../../userContext/userContext";
import {generatePodDocument, generatePodChatRoomDocument, handlePodUpload, getPodDocument} from "../../../firebase";




function NewPod(props) {


    //local variables
    const [pods, setPods] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [form, setForm] = useState({name: '', description: '', rules:''});
    const [redirect, setRedirect] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    //set function
    const set = name => {
        return ({ target: { value } }) => {
          setForm(oldValues => ({...oldValues, [name]: value }));
        }
      };

      //where pop up shows up
    const anchorConfig={
        horizontal: "center",
        vertical: "middle",
    };

    useEffect(async () => {
        if(props.podIDs.length > 0){
            props.podIDs.map(async podID => {
                await getPodDocument(podID).then(pod => {
                    if (!pods){
                        pods=[];
                    }
                    var temp=pods;
                    temp.push(pod);
                    setPods(temp);
                });
            });}
    }, []);
    console.log("debug pods",pods);


    //handle form submission
    const handleSubmit = useCallback(async event => {
        event.preventDefault();
    
        
        const name= await event.target.name.value.toString();
        const description= await event.target.description.value.toString();
        const rules= await event.target.rules.value.toString();
        const admins=[currentUser.uid];
        const regularUsers=[];
        
        
        try {
        const pod = await generatePodDocument(currentUser, {name, description, rules, admins, regularUsers});
        console.log(pod);
        await generatePodChatRoomDocument(pod, "General");
        
        setForm({name: '', description: '', rules:''});
        if (event.target.podPhoto.files[0]) {
            handlePodUpload(pod,"podProfilePicture",event.target.podPhoto.files[0], "podProfilePhotoURL");
         }
        setRedirect(true);
        } catch (error) {
        alert(error);
        }
    }, []);
    //redirect or show form
    if (redirect) {
        return <Redirect to={"/feed"} />
    }
    else{
        return (
            <div className="add-pod-container">
                <div className="pods-list-container">
                    <h4 style={{marginBottom: '0%'}}>Pods</h4>
                    {pods.length > 0 ? pods.map((pod,key) => 
                    (
                        <button className="select-pod-profile">
                        <a href={'/pod/' + pod.id} >
                        <div className="pod-display-summary">
                            <img className="pod-list-avatar" src={pod.podProfilePhotoURL} />
                            <div className="pod-list-text-display">
                                <p className="pod-list-avatar-name" key={key}>{pod.name}</p>
                                <p className="pod-description" key={key}>{pod.description}</p>
                            </div>
                        </div>
                        </a>
                        </button>
                    )) : <div>No pods yet</div>}
                </div>

                <button className="add-post-button" onClick={handleShow}>
                    Create a Pod
                </button>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header style={{background: '#fbfaf5'}}>
                        <div>
                            <h3>Create a Pod</h3>
                            <p style={{fontSize: '14px', marginBottom: '0%'}}>Talk about your business idea here!</p>    
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{background: '#fbfaf5'}}>
                        <Form onSubmit={handleSubmit} style={{ textAlign: 'left'}} >
                        <Form.Group controlId="validationFormik02">
                            <Form.Label className="form-label-text">Name</Form.Label>
                            <Form.Control required name="name" className="form-label-text" type="text" placeholder="Enter name" value={form.name} onChange={set('name')}/>
                        </Form.Group>
                        <Form.Group controlId="validationFormik01">
                            <Form.Label className="form-label-text">Description</Form.Label>
                            <Form.Control  name="description" className="form-label-text"type="text" placeholder="Description" value={form.description} onChange={set('description')}/>
                        </Form.Group>
                        {/* <Form.Group controlId="validationFormik02">
                            <Form.Label className="form-label-text">Rules</Form.Label>
                            <Form.Control required name="rules" className="form-label-text form-lg" type="text" placeholder="No Spam ..." value={form.rules} onChange={set('rules')}/>
                        </Form.Group> */}
                        <Form.Group controlId="file-upload">
                            <Form.Label className="form-label-text">Upload Pod Profile Picture</Form.Label>
                                <br></br>
                            <Form.Control required name="podPhoto" className="form-label-file" type="file" accept=".jpg, .png"/>
                        </Form.Group>
                        
                        <br></br>
                        <button style={{float: 'right'}} className="create-pod-button" type="submit">
                            Create Pod
                        </button>
                        
                        </Form>
                    </Modal.Body>
                    {/* <Modal.Footer style={{background: '#fbfaf5'}}>
                        <button className="add-post-button" type="submit" onClick={handleClose}>
                            Create Pod
                        </button>
                    </Modal.Footer> */}
                </Modal>
            </div>
        )
    }
}

export default withRouter(NewPod);