import React, {useState, useEffect, useContext} from 'react';
import FriendsList from '../Portal/Components/js/FriendsList'
import { AuthContext } from "../userContext/userContext";
import { Row, Col} from 'react-bootstrap';
import SideNavBar from '../Portal/Components/js/SideNavBar'
import NotFoundPage from './NotFoundPage'
import '../Portal/Components/css/NetworkComponents.css';
import profileImage from '../Images/profile-image.png'
import { getFriends, getUsersByIDs } from '../Components/js/GetUserData';
import { getAllObjectsNoLimit, getAllUsers } from '../firebase';
import NetworkUserList from '../Portal/Components/js/NetworkUserList';
import NetworkPodList from '../Portal/Components/js/NetworkPodList';
import MultiSelect from 'react-multi-select-component';
import { Popover } from 'react-tiny-popover';
import FriendRequests from '../Portal/Components/js/FriendRequest';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';



const NetworkPage = (props) => {
    //get current user using context with firebase 
    const { currentUser } = useContext(AuthContext);

    const [showFriends, setShowFriends] = useState(true);
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);




    //get options for each filter
    useEffect( async ()=>{
        try {
            const friends = await getFriends(currentUser);
            setFriends(friends);
            setLoading(false);
        } catch(error) {
            console.log(error);
        }
        

    },[]);
    
    if (!currentUser) return <NotFoundPage />
    
    if (currentUser) {
    return (
        <div className="network-container" >
                <div className="portal-bottom-tab-nav"> 
                    <MobileNavBar />
                </div>
                <Row>
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                    <Col sm={7} >
                        <div className="friend-requests-mobile">
                            <FriendRequests /> 
                        </div>
                        {!loading && friends && friends.length > 0 ? <NetworkUserList currentUser={currentUser} users={friends} showFriends={showFriends} /> : 'No Friends Yet'}
                    </Col>
                    <Col sm={4} style={{paddingLeft: '3%', paddingRight: '4%'}}>
                    </Col>
                </Row>
            

        </div>
    )}
}

export default NetworkPage;
