import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import './App.css';
import AboutPage from './Pages/AboutPage';
import SocialPage from './Pages/SocialPage';
import NotFoundPage from './Pages/NotFoundPage';
import UserPage from './Pages/UserPage';
import HomePage from './Pages/HomePage';
import NavBar from './Components/js/NavBar';
import LoginPage from './Pages/LoginPage';
import SignupPage from './Pages/SignupPage';
import ProfilePage from './Pages/ProfilePage';
import FeedPage from './Pages/FeedPage';
import PodsPage from './Pages/PodsPage';
import ChatRoom from './Pages/ChatRoom';
import Footer from './Components/js/Footer';
import ContactUsPage from './Pages/ContactUsPage';
import "bootstrap/dist/css/bootstrap.min.css";
import AdditionalDataPage from './Pages/AdditionalDataPage';
import FriendProfile from './Pages/FriendProfile';
import ViewPodPage from './Pages/ViewPodPage';
import NetworkPage from './Pages/NetworkPage';
import {AuthProvider} from './userContext/userContext';
import PrivateRoute from './userContext/privateRoute';
import SearchPage from './Pages/SearchPage';


class App extends Component {



  render () {
    return (
      <>
      <Router>
      <AuthProvider>
        <div className="App">
  
            <Switch>
              <Route path="/" exact >
                <NavBar />
                <HomePage />
                <Footer />
              </Route>
              <Route path="/about" exact >
                <NavBar />
                <AboutPage />
                <Footer />
              </Route>

              <PrivateRoute path="/pods" component={PodsPage} />
              <PrivateRoute path="/pod/:id" component={ViewPodPage} />
              
              <Route path="/contactus" exact >
                <NavBar />
                <ContactUsPage />
                <Footer />
              </Route>
              
              <Route path="/additionalData" component={AdditionalDataPage}/>

              
              {/* <Route path="/signup" >
                <NavBar />
                <SignupPage />
                <Footer />
              </Route> */}

              <PrivateRoute path="/profile">
                <ProfilePage />
              </PrivateRoute>
              
              <PrivateRoute path="/messages">
                <ChatRoom />
              </PrivateRoute>
              
              <PrivateRoute path="/message/:id">
                <ChatRoom />
              </PrivateRoute>

              <PrivateRoute path="/feed">
                <FeedPage />
              </PrivateRoute>

              <PrivateRoute path="/network">
                <NetworkPage />
              </PrivateRoute>

              <PrivateRoute path="/search">
                <SearchPage />
              </PrivateRoute>

              <PrivateRoute path="/friendprofile/:id">
                <FriendProfile />
              </PrivateRoute>

              <Route path="/login" >
                <NavBar />
                <LoginPage />
                <Footer />
              </Route>


              <Route component={NotFoundPage} />
            </Switch>
          </div>
        
        {/* <Footer /> */}
        </AuthProvider>
      </Router>
      </>
    );

  }

}

export default App;
