import React, { useEffect, useState } from 'react'
import {HiOutlineSearch } from 'react-icons/hi'
import ProfileImg from '../../../Images/profile-image.png'
import Select from 'react-select';
import { getAllObjectsNoLimit } from '../../../firebase';


export default function FeedSearchBar(props) {
    const options= [];
    const [topics, setTopics] = useState([]);
    const [topic, setTopic]= useState({});

    useEffect(async() => {
        const topics = await getAllObjectsNoLimit('topics');
        setTopics(topics);
    } , []);

    const handleChange = (newValue, actionMeta)=>{
        console.log(newValue);
        setTopic(newValue);
    }

    const handleFilter = () =>{
        if (topic.posts){
            props.getPostsByTopic(topic);
        }
    }

    return (
        <div className="search-bar-container">
            <div style={{paddingLeft: '3%', width: '40%', textAlign: 'left'}}>
                <Select             
                    onChange={handleChange} 
                    isClearable={true} 
                    isSearchable={true} 
                    options={topics}
                    value={topic}
                />
            </div>
            <div style={{width: '5%'}}/>
            <button className="interest-buttons" onClick={handleFilter}>Filter Posts</button>
        </div>
    )
}
