import React, { useState, useCallback, useContext } from 'react';
import '../Components/css/LoginPage.css';
import { Form, Button, Row, Col, Container, Jumbotron, Nav } from 'react-bootstrap';
import {auth, signInWithGoogle} from '../firebase';
import GoogleButton from 'react-google-button';
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "../userContext/userContext";


const LoginPage = ({history}) => {
      //login state variable
      const [login, setLogin] = useState({
          email: '', password: ''
        });
        
        //update username plus password based on form
        const set = name => {
          return ({ target: { value } }) => {
            setLogin(oldValues => ({...oldValues, [name]: value }));
          }
        };
  
  
  
  
  
      //login with firebase
      console.log(login);
      const handleLogin = useCallback(
          async event => {
            event.preventDefault();
            
            const  email= await event.target.email.value.toString();
            const password= await event.target.password.value.toString();
           
            try {
              await auth.signInWithEmailAndPassword(email, password);
              history.push("/profile");
            } catch (error) {
              alert(error);
            }
          },
          [history]
        );
      

        //get current user with this command
        const { currentUser } = useContext(AuthContext);
        console.log(currentUser);
    
        if (currentUser) {
          if ('lastName' in currentUser) {
            return  <Redirect to='/profile'  />;;
          }
          else  {
            return <Redirect to='/additionalData'  />;;
          }
      }
      else{

    return (
        <>
        <div className="login-container">
            <Container>
            <br/> <br /> <br/> <br />

            <Row>
                    <Col sm={5} style={{textAlign: 'left', paddingLeft: '60px'}}>
                        <h1 className="signin-text">Log in</h1>
                        <br />
                        <text className="connect-text">Connect today.</text>
                        <br /> <br /> <br /> 
                        <h2 className="new-to-text">New to Hatch?</h2>
                        <p>Login With Your Google Credentials</p>
                        <br />
                    </Col>
                    <Col sm={7} style={{textAlign: 'left' }}>
                    <GoogleButton className="google-button" onClick={signInWithGoogle}></GoogleButton>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );}

};


export default LoginPage;