import React, {useState, useEffect, useContext} from 'react';
import '../Components/css/ProfilePage.css';
import users from '../Data/userdata';
import FriendsList from '../Portal/Components/js/FriendsList';
import NotFoundPage from './NotFoundPage';
import RecommendedFriends from '../Portal/Components/js/RecommendedFriends';
import PodsList from '../Components/js/PodsList';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import ProfileHeader from '../Portal/Components/js/ProfileHeader';
import AboutMe from '../Portal/Components/js/AboutMe';
import AskMeAbout from '../Portal/Components/js/AskMeAbout';
import MySkills from '../Portal/Components/js/MySkills';
import AdditionalInfo from '../Portal/Components/js/AdditionalInfo';
import { AuthContext } from "../userContext/userContext";
import { getFriends } from '../Components/js/GetUserData';
import NewPod from '../Portal/Components/js/NewPod';
import { getAllUsers } from '../firebase';
import SideNavBar from '../Portal/Components/js/SideNavBar';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';


const ProfilePage = (props) => {



    const [allUsers, setAllUsers] = useState([]);


    
    
    //pop up for pod

    const [popUp, setPop] = useState(false);



    //get current user using context with firebase 
    const { currentUser } = useContext(AuthContext);
    console.log(currentUser);
    

    
        //save the pods in a local state variable
    const [local, setLocal] = useState({
        pods:[],friends:[], rePull:true
    });
    
        
    console.log("pods", currentUser.pods);
    useEffect(async () => {
        
        const data=await getFriends(currentUser).then(data => {return data}); 
        await setLocal({pods: currentUser.pods, friends:data, rePull:false});
        await getAllUsers().then((users) => {setAllUsers(users);})
    },[local.rePull]);

    console.log("users", allUsers);

    console.log(local.pods);
    console.log(local.friends);
        


    if (!currentUser) return <NotFoundPage />
    
    if (currentUser){
            
        return (
                
            <div className="profile-container" >
                <div className="portal-bottom-tab-nav"> 
                    <MobileNavBar />
                </div>
                <Row className="profile">
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                    <Col sm={7} className="profile-page-middle" style={{paddingLeft: '3%' }}>
                        <Row>
                            <ProfileHeader user={currentUser} me={true} isFriend={false} isRequestSent={true}/>
                        </Row>
                        <Row >
                            <NewPod centered podIDs={currentUser.pods} />
                        </Row>
                        <Row>
                            <AboutMe allowedToEdit={true} currentUser={currentUser} />
                        </Row>

                    </Col>
                    <Col sm={4} className="profile-page-right" style={{paddingLeft: '3%', paddingRight: '4%'}}>
                        <Row>
                            <MySkills currentUser={currentUser} me={true} />
                        </Row>
                        <Row>
                            <AskMeAbout currentUser={currentUser} me={true} />
                        </Row>
                        <Row>
                            <AdditionalInfo currentUser={currentUser} />
                        </Row>
                        <Row>
                            {/* <FriendsList friends={local.friends} /> */}
                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
    


}

export default ProfilePage;