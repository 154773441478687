import React from 'react';
import '../Portal/Components/css/PodsComponents.css';
import pods from '../Data/poddata';
import { Row, Col, Container } from 'react-bootstrap';
import NotFoundPage from './NotFoundPage';
import MembersList from '../Components/js/MembersList'
import RecommendedPods from '../Components/js/RecommendedPods';
import PodsHeader from '../Portal/Components/js/PodsHeader';
import MySkills from '../Portal/Components/js/MySkills';
import AboutMe from '../Portal/Components/js/AboutMe';
import editIcon from '../Images/editIcon.png';
import SideNavBar from '../Portal/Components/js/SideNavBar';
import PodMembers from '../Portal/Components/js/PodMembers';
import SimilarStartups from '../Portal/Components/js/SimilarStartups';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';


const PodsPage = () => {
    const defaultAboutMe = "I like to hang out with friends and do fun stuff. I like to code and do things on figma. I am still learning. What are we doing with this website. Funny you ask. We are connecting individuals.";

    return (

       <div className="pods-page-container">
            <div className="portal-bottom-tab-nav"> 
                <MobileNavBar />
            </div>
            <Row className="profile">
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                <Col sm={7} style={{paddingLeft: '3%', }}>
                    <Row>
                        <PodsHeader />
                    </Row>
                    <Row>
                        <div className="about-pod-section">
                            <Row style={{marginBottom: "0.5%"}}>
                                <Col sm={10}><h4>About us</h4></Col>
                                <Col sm={2} style={{textAlign: "right", paddingRight: "4%"}}>
                                </Col>
                            </Row>  
                                <p>
                                {defaultAboutMe}
                                </p>
                        </div>
                    </Row>
                </Col>
                <Col sm={4} style={{paddingRight: '4%', paddingLeft: '3%'}}>
                    <Row>
                        {/* <SimilarStartups /> */}
                    </Row>
                        {/* <RecommendedPods pods={otherPods} /> */}
                </Col>
            </Row>
        </div>

    )

}

export default PodsPage;