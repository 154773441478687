import React, { useState } from 'react'
import '../css/ProfileComponents.css'
import ProfileImg from '../../../Images/profile-image.png'
import ProfileOutline from '../../../Images/profile-outline.jpg'
import Skyline from '../../../Images/SkylineBG.png'
import editIcon from '../../../Images/editIcon.png';
import facebooklogo from '../../../Images/facebooklogo.png';
import twitterlogo from '../../../Images/Twitter-Logo.png';
import linkedinlogo from '../../../Images/linkedinlogo.png';
import gmaillogo from '../../../Images/Gmail-logo.png'
import {Link} from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { sendFriendRequest } from '../../../Components/js/GetUserData';

function ProfileHeader(props) {
    const [sending, setSending]= useState(false);
    
    const sendRequest = async () => {
        console.log(props.user, props.currentUser);
        await sendFriendRequest(props.currentUser, props.user.uid);
        setSending(true);
        //window.location.reload();
    }
    
    
    return (
        <div className="profile-header">
            <div className="profile-top">
                <img src={Skyline} className="profile-bg-image" />
            </div>

            <div className="profile-bottom">
                <div className="profile-description">
                    <h2>{props.user.firstName ? props.user.firstName : ""}  {props.user.lastName ? props.user.lastName : ""} </h2>
                    <h4>{props.user.currentRole ? props.user.currentRole : props.user.college}</h4>
                    <p style={{marginBottom: '0%', color: 'gray'}}>{props.user.location ?  props.user.location : ""}</p>
                    <p className="number-connections">{props.user.friends ? props.user.friends.length : "0"} connections</p>
                    <p className="number-connections">{props.user.pods ? props.user.pods.length : "0"} pods</p>

                    <div className="profile-links">
                        <a><img style={{width: "13%"}} src={linkedinlogo} /></a>
                        <a><img style={{width: "20%"}} src={gmaillogo} /></a>
                        <a><img style={{width: "11%"}} src={facebooklogo} /></a>
                        <a><img style={{width: "14%"}} src={twitterlogo} /></a>
                    </div>
                </div>
                <div className="edit-profile-section" style={{paddingRight: "2%", marginTop: "2%"}}>
                {props.me ? 
                    <Link to="/additionalData" className="edit-profile-button-desktop">
                        <img className="edit-image" src={editIcon} />
                        Edit Profile
                    </Link> : null
                }
                {props.me ? 
                    <Link to="/additionalData" className="edit-profile-button-mobile">
                        <img src={editIcon} />
                    </Link> : null
                }
                {props.isFriend ? 
                    <button onClick={props.chatWith} className="message-button">
                    Message
                    </button>: null
                }
                {!props.isRequestSent && !sending && !props.isFriend ? 
                    <button onClick={sendRequest} className="message-button">
                        Connect
                    </button>: null
                }
                {sending && !props.me && !props.isFriend ? 
                    <button onClick={sendRequest} className="message-button">
                        Pending
                    </button>: null
                }
                </div>
            </div>
            <div className="profile-avatar-holder">
                <img className="profile-avatar" src={props.user.photoURL}>
                </img>
            </div>
        </div>
    )
}

export default ProfileHeader;
