import React, {useEffect, useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import ProfileImg from '../../../Images/profile-image.png'
import nomad from '../../../Images/nomad.png'
import luma from '../../../Images/luma.png'
import {getObjectDocument,getUserDocument} from '../../../firebase'
import FriendProfile from '../../../Pages/FriendProfile'
import {IoSaveOutline, IoTrashOutline, IoSave} from 'react-icons/io5'
import {deletePost, deletePostById} from '../../../Components/js/DeleteFunctions'
import InteractWithPostButton from './InteractWithPostButton'
import { savePost } from '../../../Components/js/GetUserData'


export default function Feed({posts, currentUser, repull}) {

    const [pods, setPods] = useState([]);
    const [users, setUsers] = useState([]);
    const [pull, setPull] = useState(true);
    const [commentUsers, setCommentUsers] = useState([]);
    const [admin, setAdmin] = useState(false);

    const chatWith = (user) => {
        if (user.chatRooms){
            const roomId= currentUser.chatRooms.find(room => user.chatRooms.includes(room));
            if (roomId) {
                window.location.href = `/messages/${roomId}`;
            }
        }
    }

    console.log(posts);
    //initialize the pods and users connected to posts
    useEffect( async () => {
        if (currentUser.uid  === "RVij1oNXAvb3KW43FCFzmzkfL823" || currentUser.uid=== "EiDSDhl1CxM5CFIti0vFD2O10193"){
            setAdmin(true);
        }

        if (posts && pull) {
            var tempPods = [];
            var tempUsers = [];
            var tempCommentUsers= [];
            console.log('posts', posts);
            setPods([]);
            setUsers([]);
            setCommentUsers([]);
            for (let i = 0; i < posts.length; i++) {
                const post = posts[i];

                if (post.podID){
                const pod = await getObjectDocument('pods',post.podID);
                tempPods.push(pod);
                console.log(pod);
                }
                if (post.createdByID){
                    const user = await getObjectDocument('users',post.createdByID);
                    tempUsers.push(user);
                    console.log(user);
                }

                if (post.comments){
                    for (var j=0; j<post.comments.length; j++){
                        if (post.comments[j].user){
                            var userTemp = await getUserDocument(post.comments[j].user);
                            tempCommentUsers=tempCommentUsers.filter(usr => usr.uid !== userTemp.uid);
                            tempCommentUsers.push(userTemp);

                        }
                    }
                }

                if (post.interested){
                    for (var k=0; k<post.interested.length; k++){
                        if (post.interested[k].user){
                            var userTemp = await getUserDocument(post.interested[k].user);
                            tempCommentUsers=tempCommentUsers.filter(usr => usr.uid !== userTemp.uid);
                            tempCommentUsers.push(userTemp);
                        }
                    }
                }
            }
            setPods(tempPods);
            setUsers(tempUsers);
            setPull(false);
            setCommentUsers(tempCommentUsers);
        }
        
    }, [posts]);


    console.log("comment",commentUsers);

    //delete a post function
    const handleDelete= async(post) => {
        var work=await deletePost(currentUser,post);
        console.log(work);
        repull();
    }

    //save a post function
    const handleSave= async(post) => {
        if (post.savedBy){
            if (post.savedBy.includes(currentUser.uid)){
                return;
            }
        }
        if (await savePost(currentUser,post) === true){
            repull();
        }
    }




    return (
        <>

            {posts && posts.length > 0 && pull===false
                ? posts.slice(0).reverse().map((post,key) => {

                    var pod=null;
                    var user=null;
                    if (post.podID){
                    pod=pods.find(pod => pod.id===post.podID);}
                    if (post.createdByID){
                    user=users.find(user => user.id===post.createdByID);}
                    console.log("here post and user",user, post);
      
                    return (
                        <div className="feed-container">
                            {admin ? <button className="interest-buttons" 
                                style={{alignSelf: 'flex-start'}}
                                onClick={async ()=>{await deletePostById(post); repull();}}>
                                    Delete Post as Admin
                                </button>: ''}
                            {user ? 
                            <div className="feed-post-user" key={key}>
                                <button className="avatar-feed-button">
                                    {currentUser.uid === user.id ?
                                            <a href={'/profile/'+ user.id}>
                                            <img className="avatar-feed-post" src={user.photoURL} />
                                            </a>
                                        :
                                            <a href={'/friendprofile/'+ user.id}>
                                            <img className="avatar-feed-post" src={user.photoURL} />
                                            </a>
                                    }
                                </button>
                                <div className="feed-post-user-info">
                                    <a href={'/friendprofile/'+ user.id}>
                                        <h5>{user.firstName} {user.lastName}</h5>
                                    </a>
                                    <p>{post.createdAt? post.createdAt : "July 22"}</p>
                                </div>
                                {currentUser.uid === user.id ?
                                        <div style={{display: 'flex', paddingRight: '3%'}}>
                                            <button onClick={()=>handleSave(post)} className="save-button"><IoSaveOutline style={{alignSelf: 'center'}} /></button>                 
                                            <button onClick={()=> handleDelete(post)} className="save-button"><IoTrashOutline style={{alignSelf: 'center', color: 'darkred'}} /></button>
                                        </div>             
                                    :
                                        <button className="save-button"><IoSaveOutline style={{alignSelf: 'center'}} /></button>                     
                                }
                            </div>: <div className="feed-post-user" key={key}><div className="feed-post-user-info"><p>{post.createdAt? post.createdAt : "July 22"}</p></div></div>}
                            {pod ? 
                            <div className="startup-name-feed-post">
                                <h5>{pod.name}</h5>
                                <img className="startup-logo" src={pod.podProfilePhotoURL} />
                            </div> : ''}
                            <div className="feed-post-content">
                                <p> {post.description}</p>
                            </div>
                            {post.positionsWanted ?
                            <div className="roles-needed">
                                <p style={{fontWeight: "bold"}}>Roles Needed: &nbsp;</p>
                                <p style={{fontWeight: "600"}}>{post.positionsWanted}</p>
                            </div> : ''}
                            <div className="interest-buttons-container">
                                <div style={{width: '70%', textAlign: 'left'}}>
                                    <InteractWithPostButton currentUser={currentUser} post={post} />
                                </div>
                            </div>
                            <div>
                                {pod && post.interested && post.interested.length > 0  ?
                                    <div className="comments">
                                        <p style={{fontWeight: "bold"}}>Interested</p>
                                        {post.interested.map((comment, key) => {
                                            var person= commentUsers.find((usr) => {return usr.uid === comment.user});
                                            return (
                                                <div className="comment-row">
                                                    {/* <img src={comment.user.photoURL}/> */}
                                                    <img className="comments-user-image" src={person && person.photoURL? person.photoURL : ProfileImg} />
                                                    {/* <p>{comment.user.name}</p> */}
                                                    <div className="comment-right">
                                                        <p className="comment-user" >{person ? person.firstName +' '+ person.lastName: 'Loading User'}</p>
                                                        <p className="comment-message" >{comment.message}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div> : null
                                }
                                {!pod && post.comments && post.comments.length > 0 ?
                                    <div className="comments">
                                        <p style={{fontWeight: "bold"}}>Comments</p>
                                        {post.comments.map((comment, key) => {
                                            var person= commentUsers.find((usr) => {return usr.uid === comment.user});
                                            return (
                                                <div className="comment-row">
                                                    {/* <img src={comment.user.photoURL}/> */}
                                                    <img className="comments-user-image" src={person && person.photoURL? person.photoURL : ProfileImg} />
                                                    {/* <p>{comment.user.name}</p> */}
                                                    <div className="comment-right">
                                                        <p className="comment-user" >{person ? person.firstName +' '+ person.lastName: 'Loading User'}</p>
                                                        <p className="comment-message" >{comment.message}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>: null
                                }
                            </div>
                        </div>
                    );
                })
                : <div>Loading!</div>
            }
        </>
    )
}
