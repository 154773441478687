import React, {useState, useEffect, useContext} from 'react';
import FriendsList from '../Portal/Components/js/FriendsList'
import { AuthContext } from "../userContext/userContext";
import { Row, Col} from 'react-bootstrap';
import SideNavBar from '../Portal/Components/js/SideNavBar'
import NotFoundPage from './NotFoundPage'
import '../Portal/Components/css/NetworkComponents.css';
import profileImage from '../Images/profile-image.png'
import { getFriends, getUsersByIDs } from '../Components/js/GetUserData';
import { getAllObjectsNoLimit, getAllUsers } from '../firebase';
import NetworkUserList from '../Portal/Components/js/NetworkUserList';
import MultiSelect from 'react-multi-select-component';
import { Popover } from 'react-tiny-popover';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';




const SearchPage = (props) => {
    //get current user using context with firebase 
    const { currentUser } = useContext(AuthContext);

    const [first, setFirst] = useState(true);
    const [friends, setFriends] = useState([]);
    const [selectedPassions, setSelectedPassions] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [searchResult, setSearchResult] = useState(true);

    const [selected, setSelected] = useState([]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [passionOptions, setPassionOptions] = useState([]);
    const [skillOptions, setSkillOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    
    const overideLocationStrings = {
        "allItemsAreSelected": "All locations.",
        "selectSomeItems": "Select City..."
    }
    const overideSkillStrings = {
        "allItemsAreSelected": "All skills.",
        "selectSomeItems": "Select Skill..."
    }

    const overidePassionStrings = {
        "allItemsAreSelected": "All passions.",
        "selectSomeItems": "Select Passion..."
    }

    //get options for each filter
    useEffect( async ()=>{
        getAllObjectsNoLimit("skills").then(data=>{
            setSkillOptions(data);
        });
        getAllObjectsNoLimit("interests").then(data=>{
            setPassionOptions(data);
        });
        getAllObjectsNoLimit("locations").then(data=>{
            setLocationOptions(data);
        });

    },[]);


    //handle filter search
    const searchForUsers = async () => {
        setFirst(false);
        var userIds=[];
        var locationIds=[];
        var skillIds=[];
        var passionIds=[];
        for(var i=0;i<selectedLocations.length;i++){
            if (selectedLocations[i].users && selectedLocations[i].users.length > 0){
                locationIds=locationIds.concat(selectedLocations[i].users);
                userIds=userIds.concat(selectedLocations[i].users);
            }
        }
        for(var i=0;i<selectedSkills.length;i++){
            if (selectedSkills[i].users && selectedSkills[i].users.length > 0) {
                skillIds=skillIds.concat(selectedSkills[i].users);
                userIds=userIds.concat(selectedSkills[i].users);
            }
        }
        for(var i=0;i<selectedPassions.length;i++){
            if (selectedPassions[i].users && selectedPassions[i].users.length > 0) {
                passionIds=passionIds.concat(selectedPassions[i].users);
                userIds=userIds.concat(selectedPassions[i].users);
            }
        }
       

        console.log(userIds);
        if (locationIds.length > 0) {
            userIds=userIds.filter(id => locationIds.includes(id));
        }

        if (skillIds.length > 0) {
            userIds=userIds.filter(id => skillIds.includes(id));
        }
        if (passionIds.length > 0) {
            userIds=userIds.filter(id => passionIds.includes(id));
        }

        console.log(userIds);
        userIds= [...new Set(userIds)];
        userIds=userIds.filter(id => id != currentUser.uid);
        if (userIds.length > 0) {
            setSearchResult(true);
            getUsersByIDs(userIds).then(data=>{
                setFriends(data);
            })
        }
        else{
            setSearchResult(false);
        }

    }

    

    if (!currentUser) return <NotFoundPage />
    
    if (currentUser) {
    return (
        <div className="network-container" >
                <div className="portal-bottom-tab-nav"> 
                    <MobileNavBar />
                </div>
                <Row>
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                    <Col sm={7} style={{paddingLeft: '3%', }}>
                        <div className="network-filter-container">
                            <div className="multi-select-container">
                                <MultiSelect 
                                    options={passionOptions}
                                    value={selectedPassions}
                                    onChange={setSelectedPassions}
                                    labelledBy="Select"
                                    placeholder="Select Passion..."
                                    overrideStrings={overidePassionStrings}
                                    className="multi-select"
                                />
                            </div>
                            <div className="multi-select-container">
                                <MultiSelect 
                                    options={locationOptions}
                                    value={selectedLocations}
                                    onChange={setSelectedLocations}
                                    labelledBy="Select"
                                    placeholder="Select City..."
                                    overrideStrings={overideLocationStrings}
                                />
                            </div>
                            <div className="multi-select-container">
                                <MultiSelect 
                                    options={skillOptions}
                                    value={selectedSkills}
                                    onChange={setSelectedSkills}
                                    labelledBy="Select"
                                    placeholder="Select Skill..."
                                    overrideStrings={overideSkillStrings}
                                />
                            </div>
                            <button className="message-friend-button" onClick={()=>{searchForUsers();} }>Filter</button>
                        </div>
                        <div className="mobile-network-filter-container">
                            <div className="mobile-filters">
                            <MultiSelect 
                                options={passionOptions}
                                value={selectedPassions}
                                onChange={setSelectedPassions}
                                labelledBy="Select"
                                placeholder="Select Passion..."
                                overrideStrings={overidePassionStrings}
                                className="multi-select"
                            />
                            <MultiSelect 
                                options={locationOptions}
                                value={selectedLocations}
                                onChange={setSelectedLocations}
                                labelledBy="Select"
                                placeholder="Select City..."
                                overrideStrings={overideLocationStrings}
                            />
                            </div>
                            <div className="mobile-filters">
                                <MultiSelect 
                                    options={skillOptions}
                                    value={selectedSkills}
                                    onChange={setSelectedSkills}
                                    labelledBy="Select"
                                    placeholder="Select Skill..."
                                    overrideStrings={overideSkillStrings}
                                />
                                <button className="filter-button" onClick={()=>{searchForUsers();} }>Filter</button>
                            </div>
                        </div>
                        {searchResult && !first ? <NetworkUserList currentUser={currentUser} users={friends} showFriends={false} /> : "No Users Match This Search" }
                    </Col>
                    <Col sm={4} style={{paddingRight: '4%', paddingLeft: '3%'}}>
                    </Col>
                </Row>
            

        </div>
    )}
}

export default SearchPage;
