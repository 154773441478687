import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { getObjectDocument } from '../../../firebase';
import { sendPodInvite} from '../../../Components/js/GetUserData'
import { Button, Modal} from 'react-bootstrap';

export default function InviteToPod(props) {
    const [options, setOptions] = useState([]);
    const [pod, setPod] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(async () => {
        var podIds=[]
        if (props.currentUser.pods){
            podIds=props.currentUser.pods;
        }
        if(podIds){
            
            setOptions([]);
            var podTemp=[];
            for (var i=0; i<podIds.length; i++){
                var id = podIds[i];
                var pod = await getObjectDocument("pods",id);
                var podOption= {label:pod.name, value:pod.id};
                if (pod){
                    if (pod.invitedUsers && pod.invitedUsers.includes(props.friend.uid)) {
                    }
                    else{
                        podTemp.push(podOption);
                    }
                }
            }
            podTemp= podTemp.filter(pod => !props.friend.pods.includes(pod.value));
            setOptions(podTemp);
            
        }
    }, [props.currentUser.pods]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("pod submit",pod);
        if (pod && pod.value){
            console.log("pod",pod.value);
            await sendPodInvite(props.friend, pod.value);
        }
        handleClose();
        setPod(null);
    }

    const handleChange = (newValue, actionMeta) => {
        
        //console.log(newValue);
        setPod(newValue);
      };

    return (
        <>
         <button className="message-friend-button" onClick={handleShow}>
            Invite to Pod
        </button>
                   
        
        <Modal show={show} onHide={handleClose}>
            <div>
                <Modal.Header style={{background: '#fbfaf5'}} >
                    <Modal.Title>Invite {props.friend.firstName} to Pod</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select 
                        theme={theme => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                            ...theme.colors,
                            primary25: '#e6e6e6',
                            primary50: '#e6d18e',
                            primary: '#ebbd26',
                            },
                        })}                        
                        onChange={handleChange} 
                        isClearable={true} 
                        isSearchable={true} 
                        options={options} 
                        value={pod} 
                    />
                </Modal.Body>
                <Modal.Footer >
                    <button className="add-post-button" onClick={handleSubmit}> Send Invite </button>
                </Modal.Footer>
            </div>
        </Modal>
        </>
    );
}
