import React from 'react';
import '../Components/css/ContactUsPage.css'
import contactUs from '../Images/contactus.svg'

const ContactUsPage = () => {

        return (
            <div className="contactus-page-container">
                <div className="page-landing"> 
                    <div className="landing-left">
                        <h2 style={{color: (37, 36, 55)}}>We would love to hear from you.</h2>
                        <div className="input-form">
                            <h4>Contact Information</h4>
                            <br />
                            <input placeholder="First Name" className="input-field" />
                            <br /> 
                            <input placeholder="Last Name"  className="input-field" />
                            <br /> 
                            <input placeholder="Email Address" className="input-field" />
                            <br /> 
                            <textarea rows="5" cols="40" style={{paddingTop: '2%'}} placeholder="Tell us what's up"  className="input-field" />
                            <button className="contactus-button">SEND MESSAGE</button>
                        </div>
                    </div>
                    <div className="landing-right-contact">
                        <img src={contactUs} className="contactus-image" />
                    </div>
                </div>


            </div>
        
        );

}

export default ContactUsPage;