import React, { useEffect, useState } from 'react';
import '../css/ProfileComponents.css';
import { Row, Col } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { getAllObjectsNoLimit } from '../../../firebase';
import {addSkills} from '../../../Components/js/GetUserData'
import editIcon from '../../../Images/editIcon.png';
import { IoTrashOutline } from 'react-icons/io5';
import {removeSkill} from '../../../Components/js/DeleteFunctions'


export default function MySkills(props) {

    const getFirstHalf= (skills)=>{
        var temp=[];
        for (var i=0; i<Math.ceil(skills.length/2); i++){
            temp.push(skills[i]);
        }
        return temp;
    }

    const getSecondHalf= (skills)=>{
        var temp=[];
        for (var i=Math.ceil(skills.length/2); i<skills.length; i++){
            temp.push(skills[i]);
        }
        return temp;
    }

    const [skills, setSkills] = useState([]);
    const [options, setOptions]= useState([]);
    const [pull, setPull] = useState(false);
    const [userSkills, setUserSkills] = useState([]);
    const [edit, setEdit] = useState(false);

    

    useEffect(async () => {
        var optionsTemp= await getAllObjectsNoLimit("skills");
        if (props.currentUser.skills){
            for (var i=0; i<props.currentUser.skills.length; i++){
                optionsTemp=optionsTemp.filter(opt=> opt.id !== props.currentUser.skills[i].id);
            }
        }
        setOptions(optionsTemp);
        if (props.currentUser.skills){
            setUserSkills(props.currentUser.skills);}
    }, [pull]);

    
    const handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        setSkills(newValue);
      };
      const submitInterest= async ()=>{
        console.log("submit", skills);
        var temp=skills;
        if (props.currentUser.skills){
            for (var i=0; i<props.currentUser.skills.length; i++){
                temp=temp.filter(opt => opt.value !== props.currentUser.skills[i].value);
            }
        }
        if (temp.length>0){
            await addSkills(props.currentUser, temp);
        }
        setSkills([]);
        var temporary=userSkills;
        temporary.concat(temp);
        setUserSkills(temporary);
        var optionsTemp= options;
        for (var i=0; i<temporary.length; i++){
            optionsTemp=optionsTemp.filter(opt=> opt.id !== temporary[i].id);
        }
        setOptions(optionsTemp);
      }


      const handleDelete= async (id)=>{
        var temp = true;
        if (props.currentUser && id){
            temp= removeSkill(props.currentUser, id);
        }

        if (temp){
            var temporary=userSkills.filter(skill=> skill.id !== id);
            setUserSkills(temporary);
        }
  }


    return (
        <div className="myskills-section">
            <div className="about-header">
                <div className="about-title" ><h4>Skills</h4></div>
                <div style={{textAlign: "right", paddingRight: "4%"}}>
                    {props.me ? 
                        <button onClick={() => {setEdit(!edit)}} style={{padding: '2%'}} className="edit-about-me">
                        {!edit ?
                            <img src={editIcon} />
                            :
                            <p className="about-edit-cancel" style={{paddingLeft: '0%'}}>Cancel</p>
                        }
                        </button>
                    : ''}
                </div>
            </div>
            {edit ?
            <CreatableSelect
                id="select-interest"
                isMulti
                value={skills}
                onChange={handleChange}
                options={options}
            />: ''}
            {edit ?
                <button className="add-skill-button" onClick={submitInterest}>Add Skill</button>:''}
            <Row>
                <Col>
                    {getFirstHalf(userSkills)? getFirstHalf(userSkills).map((skill, key)=> {
                        return(
                        <div className="skill-row">
                            <div className="skill-text"> 
                                <p style={{marginBottom: '0%'}} key={key}>{skill.value}</p> 
                            </div>
                            {edit ?
                                <div className="trash-icon" >
                                    <button onClick={()=>handleDelete(skill.id)} className="delete-icon-button">
                                        <IoTrashOutline style={{color: 'red'}} />
                                    </button>
                                </div>
                            : '' }
                        </div>
                        );
                        }
                    )
                    :''}
                </Col>
                <Col>
                    {getSecondHalf(userSkills)? getSecondHalf(userSkills).map((skill, key)=> {
                        return(
                        <div className="skill-row">
                            <div className="skill-text">
                                <p style={{marginBottom: '0%'}} key={key}>{skill.value}</p>
                            </div>
                            {edit ?
                                <div className="trash-icon" >
                                    <button onClick={()=>handleDelete(skill.id)} className="delete-icon-button">
                                        <IoTrashOutline style={{color: 'red'}} />
                                    </button>
                                </div>
                            : '' }
                        </div>
                        );
                        }
                    )
                    :''}
                </Col>
            </Row>
        </div>
    )
}
