import React from 'react';
import notFound from '../Images/notFound.svg'

const NotFoundPage = () => {
    return (
        <>
            <div style={{height: "auto", backgroundColor: "#fff7dc", textAlign: "left"}}>
                <h1 style={{padding: "100px 0px 0px 200px"}}>404: Page Not Found</h1>
                <img src={notFound} style={{width: "35%", padding: "0px 0px 50px 200px"}} />
            </div>

        </>

    )

};

export default NotFoundPage;