import React from 'react'
import '../css/AboutPage.css';
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default function Faqs() {
    return (
            <div className="faqs">
                <br />
                    <h2>FAQs</h2>
                    <div className="faqs-row">
                        <div className="faq-accordian">
                            <Accordion>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="0">
                                    What is Hatch?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="0">
                                    <Card.Body >Hatch is a social networking platform that 
                                        connects people based on their passion and skills.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="1">
                                    How does Hatch work?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="1">
                                    <Card.Body >
                                        You create your profile and connect with other people on the platform. 
                                        You create a pod, add people to your pod, or join a pood and Boom!
                                        You have your business team and a potential million dollar idea!.
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="2">
                                    What is a pod?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="2">
                                    <Card.Body >
                                        A pod is a business idea. It can be about anything!
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="3">
                                    What makes Hatch different from LinkedIn?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="3">
                                    <Card.Body >
                                        Hatch is made for passionate and entreprenuerial college students. 
                                        We want to make it as simple as possible for people
                                        to network with anyone who shares similar interests or needs his or her skills. 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div className="faq-divider" />
                        <div className="faq-accordian">
                            <Accordion >
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="0">
                                    Can I message someone before being a friend?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="0">
                                    <Card.Body >
                                        No. One member has to accept the connection before both parties can communicate 
                                        with one another. 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="1">
                                    What do I put in my profile?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="1">
                                    <Card.Body >
                                        You will upload your name, city, state, university (if applicable),
                                        major, 45-sec video, passions, and skills.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="2">
                                    How do I search for my potential connections?
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="2">
                                    <Card.Body >
                                        You go to the search page and filter out what kind of person you are looking for.
                                        You can search on passion, skills, and location. 
                                        Then Hatch will show everyone who meets that criteria based on your filter. 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card style={{backgroundColor: "#fff7dc", textAlign: "left", borderStyle: "none"}} >
                                    <Card.Header >
                                    <Accordion.Toggle as={Button} style={{color: "black"}} variant="link" eventKey="3">
                                    How many connections can I have? 
                                    </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse  eventKey="3">
                                    <Card.Body >
                                        Unlimited. We want everyone to enrich their network.
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
  
                </div>
    )
}
