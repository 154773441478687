
import React, {useState} from "react";
import { Form } from "react-bootstrap";
import {sendIntrested, addComment} from '../../../Components/js/GetUserData'


export default function InteractWithPostButton(props) {

    const [dropDown, setDropDown] = useState(false);

    const But =()=> {
        if (props.post.type === 'Positions Wanted'){
            return (<button onClick={()=>setDropDown(true)} className="interest-buttons">I'm Interested!</button>);
        }
        else {
            return (<button onClick={()=>setDropDown(true)} className="interest-buttons">Comment</button>);
        }
    };

    const set = name => {
        return ({ target: { value } }) => {
          setMessage(value);
        }
      };

    const [message, setMessage] = useState('');

    const handleCancel = () => {
        setDropDown(false);
        setMessage('');
    }
    const handleSubmit = () => {
        setDropDown(false);
        if (props.post.type === 'Positions Wanted'){
            sendIntrested(props.currentUser, props.post, message);
        }
        else{
            addComment(props.currentUser, props.post, message);
        }
        setMessage('');
    }
    const name= props.post.type === 'Positions Wanted'? 'Message to Group' : 'Comment';

    return(
        <>
        
            {!dropDown ?
                <But/>:null}

            {dropDown ?
                <Form>
                    <Form.Group controlId="validationFormik02">
                        <Form.Control name="message" required className="comment-input" type="text" placeholder={name} value={message} onChange={set('message')}/>
                        <button className="interest-buttons" onClick={()=> handleSubmit()}>Submit</button>
                        <button className="interest-buttons" onClick={()=> handleCancel()}>Cancel</button>
                    </Form.Group>
                </Form>
            : null}


        </>
    );
}