import React, {useContext, useEffect, useState} from 'react';
import '../Components/css/PodsPage.css';
import pods from '../Data/poddata';
import { Row, Col, Container } from 'react-bootstrap';
import NotFoundPage from './NotFoundPage';
import MembersList from '../Components/js/MembersList'
import RecommendedPods from '../Components/js/RecommendedPods';
import PodsHeader from '../Portal/Components/js/PodsHeader';
import { AuthContext } from "../userContext/userContext";
import {getPodDocument} from '../firebase';
import {useParams} from 'react-router-dom';
import NewPodPost from '../Portal/Components/js/NewPodPost';
import SideNavBar from '../Portal/Components/js/SideNavBar';
import SimilarStartups from '../Portal/Components/js/SimilarStartups';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';

const ViewPodPage = () => {
    const {id}= useParams();
    const {currentUser} = useContext(AuthContext);
    const [pod, setPod] = useState({});

    useEffect(async () => {
        if (id) {
            setPod(await getPodDocument(id));
        }
    }, []);
    console.log(pod);
    console.log(id);

    const defaultAboutMe = "I like to hang out with friends and do fun stuff. I like to code and do things on figma. I am still learning. What are we doing with this website. Funny you ask. We are connecting individuals.";


    //create a post from here
    return (
    <>
       <div className="pods-page-container">
            <div className="portal-bottom-tab-nav"> 
                <MobileNavBar />
            </div>
            <Row className="profile">
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                <Col sm={7} style={{paddingLeft: '3%', }}>
                    <Row>
                        <PodsHeader pod={pod} />
                    </Row>
                    <Row>
                        <div className="about-pod-section">
                            <Row style={{marginBottom: "0.5%"}}>
                                <Col sm={10}><h4>About us</h4></Col>
                                <Col sm={2} style={{textAlign: "right", paddingRight: "4%"}}>
                                </Col>
                            </Row>  
                                <p>
                                {defaultAboutMe}
                                </p>
                        </div>
                    </Row>
                </Col>
                <Col sm={4}  style={{paddingRight: '4%', paddingLeft: '3%'}}>
                    <Row>
                        {/* <SimilarStartups /> */}
                    </Row>
                    <Row>
                        {pod.id ? <NewPodPost postIDs={pod.postIDs} pod={pod}/> : ''}
                        {/* <RecommendedPods pods={otherPods} /> */}
                    </Row>
                </Col>
            </Row>
        </div>

    </>
    )

}

export default ViewPodPage;