import React, {useState} from 'react';
import '../css/ProfileComponents.css';
import editIcon from '../../../Images/editIcon.png';
import {Form} from 'react-bootstrap';
import {updateFirebaseUser} from '../../../firebase';
import { Row, Col } from 'react-bootstrap';

export default function AboutMe({currentUser, allowedToEdit}) {

    const [edit, setEdit] = useState(false);

    const [sign, setSign] = useState({ 
        ...currentUser
      });

    const defaultAboutMe = "I like to hang out with friends and do fun stuff. I like to code and do things on figma. I am still learning. What are we doing with this website. Funny you ask. We are connecting individuals.";
    
      const set = name => {
        return ({ target: { value } }) => {
          setSign(oldValues => ({...oldValues, [name]: value }));
        }
      };



      const handleUpdate = async event => {

        event.preventDefault();
        if (allowedToEdit) {
        
       
          const aboutMe= await event.target.aboutMe.value.toString();
          currentUser.aboutMe=aboutMe;
          
          
          try {
            await updateFirebaseUser(currentUser, {aboutMe});
            setEdit(false);

          } catch (error) {
            alert(error);
          }
        }
      };

    if (!edit) {
    return (
        
        <div className="about-section">
          <div className="about-header">
            <div className="about-title" ><h4>About me</h4></div>
            <div style={{textAlign: "right", paddingRight: "4%"}}>
              {allowedToEdit? <button onClick={()=>{setEdit(!edit)}} style={{padding: '2%'}} className="edit-about-me"><img src={editIcon}  className="float-right"/></button>:''}
            </div>
          </div>  
            <p>
              {currentUser.aboutMe ? currentUser.aboutMe : defaultAboutMe}
            </p>
        </div>
      
    )}

    else {
        return (
        <div className="about-section">
          <div className="about-header">
            <div className="about-title" ><h4>About me</h4></div>
            <div style={{textAlign: "right", paddingRight: "4%"}}>
              <button onClick={()=>setEdit(false)} style={{padding: '2%'}} className="edit-about-me">
                <p className="about-edit-cancel">Cancel</p>
              </button>
            </div>
          </div>

        
        <Form onSubmit={handleUpdate} style={{height:'100%', width: '97.5%', textAlign: 'left', marginTop: '1%',paddingBottom: '3%'}} >
            <Form.Group controlId="validationFormik02">
                <Form.Control style={{height:'100px', alignSelf: 'top', verticalAlign: 'top' }} name="aboutMe" as="textarea" placeholder={currentUser.aboutMe ? currentUser.aboutMe : "Tell us about yourself"} value={sign.aboutMe} onChange={set('aboutMe')}/>
            </Form.Group>
            <button type="submit"style={{marginTop: '2%', padding: '1%', fontWeight: 600}} className="edit-about-me">Save</button>
        </Form>

            
        </div>)
    
    }


}
