import React from "react";
import { Container, Row, Col } from "react-bootstrap";
var ReactRotatingText = require("react-rotating-text");

function TypistAnim() {
  return (
        <div className="rotating-text">
          <h1>
              Trusted by entrepreneurs at every 
            <ReactRotatingText
              items={[
                " college campus.",
                " party school.",
                " hangout spot.",
              ]}
              color="#ebbd26"
            />
          </h1>
        </div>

  );
}
export default TypistAnim;
