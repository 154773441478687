import React, { useRef, useState, useEffect, useContext } from 'react';
import '../Components/css/ChatRoom.css'

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import { getFriends, getChatRooms } from '../Components/js/GetUserData'
import { addFriend, generateChatRoomDocument, updateFirebaseChat, generatePodChatRoomDocument, getPodDocument, getChatRoomDocument } from '../firebase'

import Logo from '../Images/hatchlogo.png';
import { IoChatbox, IoNewspaper, IoSearchSharp, IoMoonOutline, IoNuclearOutline } from 'react-icons/io5';
import {IoHome } from 'react-icons/io5'
import { FiSend } from 'react-icons/fi'
import SideNavBar from '../Portal/Components/js/SideNavBar';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Row, Col, Button } from 'react-bootstrap';
import { AuthContext } from "../userContext/userContext";
import { useParams } from 'react-router-dom';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';

const auth = firebase.auth();
const firestore = firebase.firestore();



// what to do for chat rooms
//create new collection called chatRoomUsers
//store each chat as collection of messages in a single documents
//store each document in both users in an array




function ChatRoom() {

    //get chat room collection
    const dummy = useRef();
    const chatRooms = firestore.collection('chatRooms');
    
    let {id}= useParams();
    console.log("chatroom id from chat button",id);

    //set up state variables
    
    const { currentUser } = useContext(AuthContext);
    const [formValue, setFormValue] = useState('');
    const [chatRoom, setChatRoom] = useState({});
    const [podChatRooms, setPodChatRooms] = useState([]);
    const [local, setLocal] = useState({pods:[],friends:[], rePull:true, chatRooms:[]});
    const [podData, setPodData] = useState([]);
    const [showMessages, setShowMessages] = useState(true);
    const [chatRoomHeader, setChatRoomHeader] = useState('');



     




    //create new chat room
    const getChatRoom = async (chattingUser) => {

        console.log("chat room",chatRoom);
        
        


        if (chatRoom.users){
        if (chatRoom.users.includes(chattingUser)) {
            return;
        }}

        const rooms=await getChatRooms(currentUser).then(data => {return data});

        var loop=true;

        for (var i=0; i<rooms.length; i++){
            if (rooms[i].users.includes(chattingUser.uid)){
                loop=false;
                setChatRoom({...rooms[i]});
                return;
            }
        }

        if (loop) {
            const chat = await generateChatRoomDocument(currentUser,chattingUser);
            console.log("new chat created");
            setChatRoom({...chat});
            local.rePull = true;
        }
        

    }


    
    //get chat room for pods
    const setPodChat=async (podIDs)=>{

        console.log("pod IDS",podIDs);

       
        var podsInit=[];
        var rooms=[];

        for (var i=0; i<podIDs.length; i++){
            var pod={};
            pod = await getPodDocument(podIDs[i]);
            console.log("pod",pod);
            
            var room= {};
            if (!pod.chatRooms) {
                room =  await generatePodChatRoomDocument(pod, "General");
            } 
            else {
                const roomID = pod.chatRooms[0];
                room = await getChatRoomDocument(roomID);
            }
            
            podsInit.push(pod);
            rooms.push(room);

        }
        console.log("pod initiliaze",podsInit);
        await setPodChatRooms(rooms);
        await setPodData(podsInit);
        console.log("pod data here", podData);

        }

    useEffect(async () => {
        
        const data=await getFriends(currentUser).then(data => {return data}); 
        const rooms=await getChatRooms(currentUser).then(data => {return data});
        await setLocal({pods: currentUser.pods, friends:data, rePull:false, chatRooms:rooms})
        await setPodChat(currentUser.pods);
        console.log("testing props",id)
        if (id){
            const roomTemp = await getChatRoomDocument(id);
            console.log("testing here",roomTemp);
            if (roomTemp){
                await setChatRoom(roomTemp);}
        }
        
    },[local.rePull, id]);

    console.log("pod chats", podChatRooms);
    console.log("pod data", podData);
    
  
  
    const sendMessage = async (e) => {
      e.preventDefault();
  
      const { uid, photoURL } = auth.currentUser;
      const messages=await chatRooms.doc(chatRoom.id).collection('messages').doc();
      updateFirebaseChat(chatRoom, {recentMsg: formValue})
      await messages.set({
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        photoURL
      })
  
      setFormValue('');
      //dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
  
    return (<>
        <div className="chat-room-overview">
            <div className="portal-bottom-tab-nav"> 
                <MobileNavBar />
            </div>
            <div className="chat-room-side-nav">
                <SideNavBar currentUser={currentUser}/>
            </div>
            {showMessages && <div className="chat-room-left">
                <Row>
                    <Col style={{alignSelf: "left", }}><h5>Messages</h5></Col>
                    <Col sm={"auto"} className="search-messages" style={{alignItems: "right", paddingRight: '10%'}}>
                        {/* <IoSearchSharp  /> */}
                    </Col>
                </Row>
                <br />
            <p style={{ fontSize: "12px", marginBottom: '0%'}}>PODS</p>
            {podChatRooms.length >0 ? podChatRooms.map((room,index) => {
                
                
                console.log("here",room);
                var pod = podData.find(pod => {return pod.id == room.podID});

                
               

               
                if (room && pod){
                return (
                    <button className="select-friend" onClick={ () => {setChatRoom(room)} }>
                        <div className="friend-display">
                            <img className="friend-avatar"  src={pod.podProfilePhotoURL ? pod.podProfilePhotoURL : null}/>
                            <div className="friend-text-display">
                                <p className="friend-avatar-name" key={index}>{pod.name}</p>
                                <p className="friend-latest-message" key={index}>{room.recentMsg}</p>
                            </div>
                        </div>
                    </button>

                )}
                else{
                    return ('')
                }
                }) : ''}
            



            <p style={{fontSize: "12px", marginBottom: '0%'}}>PERSONAL</p>
            {local.chatRooms.length >0 ? local.chatRooms.map((room,index) => {

                
                
                const friendID = room.users.find(user => user.localeCompare(currentUser.uid)!==0);
                
                
                const friend = local.friends.find(friend => friendID.localeCompare(friend.uid)===0);
                
                console.log(friend);
                if (friend){
                return (
                    <button className="select-friend" onClick={ () => {setChatRoom(room)} }>
                        <div className="friend-display">
                            <img className="friend-avatar" src={friend.photoURL} />
                            <div className="friend-text-display">
                                <p className="friend-avatar-name" key={index}>{friend.firstName}</p>
                                <p className="friend-latest-message" key={index}>{room.recentMsg}</p>
                            </div>
                        </div>
                    </button>
            
                )}
                else{
                    return ('')
                }
                 }) : ''}
                
                <p style={{fontSize: "12px", marginBottom: '0%'}}>FRIENDS</p>
                {local.friends.map((friend,index) => {
                    var inChat=true;
                    
                    for (var i = 0; i < local.chatRooms.length; i++) {
                        if (local.chatRooms[i].users.includes(friend.uid)){
                            
                            inChat=false;
                        }
                    }
                    
                    if (inChat){
                    return (
                    <button className="select-friend" onClick={ () => {getChatRoom(friend)} }>
                    <div className="friend-display">
                        <img className="friend-avatar" src={friend.photoURL} />
                        <div className="friend-text-display">
                            <p className="friend-avatar-name" key={index}>{friend.firstName}</p>
                            <p className="friend-latest-message">Start a Conversation Now!</p>
                        </div>
                    </div>
                    </button>
                    
            
                )} })}

            </div> }
            <div className="chat-room-container">
                <div className="chat-room-header">
                    <div className="show-message-container">
                        {!showMessages && 
                            <button className="show-messages" onClick={() => setShowMessages(!showMessages)}>Messages</button>
                        }
                        {showMessages && 
                            <button className="show-messages" onClick={() => setShowMessages(!showMessages)}>Hide Messages</button>
                        }

                    </div>
                </div>
                <section>
                    { chatRoom.id ? <ChatRoomElement currentUser={currentUser} chatRoom={chatRoom} dummy={dummy}></ChatRoomElement> : <h1>No Chat Room Selected</h1>}
                    

                    <form onSubmit={sendMessage}>
                        <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Create your dream team" />
                        <button type="submit" disabled={!formValue}><FiSend /></button>
                    </form>
                </section>
            </div>
            {/* <div className="chat-room-right">
                <div className="group-members-header">
                    <h6>Group Members</h6>
                </div>
                <div className="group-chat-photos">
                    <p>PHOTOS</p>
                </div>
                <div className="group-chat-attachments">
                    <p>ATTACHMENTS</p>
                </div>
            </div> */}
        </div>



    </>)
  }
  


  function ChatRoomElement(props) {
       //get messages from firestore
    const chatRooms = firestore.collection('chatRooms');
    
    const messagesTemp = chatRooms.doc(props.chatRoom.id);
    const messagesRef=messagesTemp.collection('messages');
    const query = messagesRef.orderBy('createdAt').limit(25);
    const messagesTemporary = useCollectionData(query, { idField: 'id' });
    const messages = messagesTemporary[0];
    console.log(messagesTemporary);
    console.log("messages",messages);
    

    if (!messages || messages.length==0) {
        return (<h1>No Messages Type One Now!</h1>)
    }
    else{
      return (
        <main>
            {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
            <span ref={props.dummy}></span>
        </main>);
        }
    

  }
  
  
  function ChatMessage(props) {
    const { text, uid, photoURL } = props.message;
  
    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  
    return (<>
      <div className={`message ${messageClass}`}>
        <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        <p>{text}</p>
      </div>
    </>)
  }

  export default ChatRoom;
