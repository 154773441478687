import React, { useContext, useEffect, useState } from 'react'
import ProfileHeader from '../Portal/Components/js/ProfileHeader';
import AboutMe from '../Portal/Components/js/AboutMe';
import AskMeAbout from '../Portal/Components/js/AskMeAbout';
import MySkills from '../Portal/Components/js/MySkills';
import { Row, Col, Container, Button } from 'react-bootstrap';
import '../Portal/Components/css/ProfileComponents.css'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../userContext/userContext';
import { generateChatRoomDocument, getUserDocument } from '../firebase';
import SideNavBar from '../Portal/Components/js/SideNavBar';
import { sendFriendRequest } from '../Components/js/GetUserData';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';

const FriendProfile = () =>  {

    const {currentUser}= useContext(AuthContext);
    const {id} = useParams();
    const [friend, setFriend] = useState({});
    var isFriend = false;
    var isRequestSent = false;
    if (currentUser.friends){
        isFriend = currentUser.friends.includes(id);
        if (currentUser.friendRequestsSent){
            if (currentUser.friendRequestsSent.includes(id)){
                isRequestSent = true;
            }
        }
    }

    useEffect(async () => {{
        if (id) {
            const user= await getUserDocument(id);
            await setFriend(user);}
    }}, []);

    const chatWith= async () =>{
        if (friend.chatRooms){
            const roomId= currentUser.chatRooms.find(room => friend.chatRooms.includes(room));
            if (roomId) {
                window.location.href = `/message/${roomId}`;
            }
            else {
                const room = await generateChatRoomDocument(currentUser, friend);
                window.location.href = `/message/${room.id}`;
            }
        }
    }


    //button to start a chat with the friend
    return (
        <div className="profile-container" >
            <div className="portal-bottom-tab-nav"> 
                <MobileNavBar />
            </div>
            <Row className="profile">
                    <Col sm={1} className="side-nav">
                        <SideNavBar />
                    </Col>
                <Col sm={7} style={{paddingLeft: '3%', }}>
                    <Row>
                        {friend ? <ProfileHeader user={friend} currentUser={currentUser} me={false} chatWith={chatWith} isFriend={isFriend} isRequestSent={isRequestSent} /> : <div></div>}
                    </Row>
                    <Row>
                        <AboutMe allowedToEdit={false} currentUser={friend}/>
                        {/* <Button onClick={chatWith}>Chat</Button> */}
                    </Row>
                    <Row>
                        <AskMeAbout currentUser={friend} me={false} />
                    </Row>   
                </Col>
                <Col sm={4} style={{paddingRight: '4%', paddingLeft: '3%'}}>
                    <Row>
                        <MySkills currentUser={friend} me={false} />
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default FriendProfile;