import React from 'react';
import { IoChatbox, IoNewspaper, IoMoonOutline, IoHome, IoPerson, IoPeople, IoSearch } from 'react-icons/io5';
import Logo from '../../../Images/hatchlogo.png'
import '../css/Navbars.css'

export default function SideNavBar({currentUser}) {
    return (
        <div className="side-nav-container">
            <div className="room-nav">
                <div style={{paddingTop: "30%", marginBottom: "100%", display: 'flex', justifyContent: 'center'}}>
                    <a href={'/'}><img src={Logo} className="logo"/> </a>
                </div>
                <div className="nav-icon-group" >            
                    <button className="nav-link"><a href={'/feed'}>
                            <IoHome className="icon-nav"/>
                            <p className="hover-text">Feed</p>
                    </a></button>
                    <button className="nav-link"><a href={'/messages'}>
                        <IoChatbox className="icon-nav"/>
                        <p className="hover-text">Messages</p>
                    </a></button>
                    <button className="nav-link"><a href={'/network'}>
                        <IoPeople className="icon-nav"/>
                        <p className="hover-text">Network</p>
                    </a></button>
                    <button className="nav-link"><a href={'/search'}>
                        <IoSearch className="icon-nav"/>
                        <p className="hover-text">Search</p>
                    </a></button>
                </div>
                <div style={{marginTop: "100%"}}>
                    {/* <button className="nav-link"><p>{currentUser.friendRequests ? currentUser.friendRequests.length : 0}</p><FaUserFriends className="icon-nav"/></button> */}
                    <button className="nav-link"><a href={'/profile'}>
                        <IoPerson className="icon-nav"/>
                        <p className="hover-text">Profile</p>
                    </a></button>
                </div>
            </div>
        </div>
    )
}
