import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../css/NetworkComponents.css';
import {generateChatRoomDocument} from '../../../firebase'
import InviteToPod from './InviteToPod'
import { sendFriendRequest } from '../../../Components/js/GetUserData';




export default function NetworkUserList(props) {

    const currentUser = props.currentUser;
    const chatWith= async (friend) =>{
        if (friend.chatRooms){
            const roomId= currentUser.chatRooms.find(room => friend.chatRooms.includes(room));
            if (roomId) {
                window.location.href = `/message/${roomId}`;
            }
            else {
                const room = await generateChatRoomDocument(currentUser, friend);
                window.location.href = `/message/${room.id}`;
            }
        }
    }

    return (
        <div className="friends-container">
            {props.showFriends ? <h4>Friends</h4> : <h4>Search Results</h4> }
            {props.users.length > 0 ? 
                props.users.map((friend, index) => {
                    return (
                    <div key={index}>
                        <div className="search-user-container" >
                            <a href={'/friendprofile/'+ friend.uid} className="network-friends-row">
                                <img src={friend.photoURL} className="network-friends-image" />
                                <div className="network-friends-info">
                                    <h5 className="network-friends-name">{friend.firstName} {friend.lastName}</h5>
                                    <p style={{fontSize: '14px', marginBottom: '0%'}}>{friend.currentRole}</p>
                                    <p style={{fontSize: '10px', marginBottom: '0%'}}>{friend.college}</p>
                                </div>
                            </a>
                            <div className="friend-interact-buttons">
                                <InviteToPod currentUser={props.currentUser} friend={friend} />
                                { props.currentUser.friends.includes(friend.uid) ?
                                <button onClick={()=>chatWith(friend)} className="message-friend-button" >
                                    Message
                                </button> : 
                                <button onClick={() => {
                                    if (props.currentUser.friendRequestsSent && !props.currentUser.friendRequestsSent.includes(friend.uid)) {
                                        sendFriendRequest(props.currentUser, friend.uid)}}} className="message-friend-button" >
                                    {props.currentUser.friendRequestsSent && props.currentUser.friendRequestsSent.includes(friend.uid) ?
                                        'Pending' :
                                        'Connect'
                                    }
                                </button>
                                }
                            </div>
                        </div>
                    </div>)
                })                
            : 'Loading'}

        </div>
    );
}