import React from 'react'
import '../css/PodsComponents.css'
import ProfileImg from '../../../Images/profile-image.png'
import ProfileOutline from '../../../Images/profile-outline.jpg'
import logo from '../../../Images/OmeletteLogo.png'
import nomad from '../../../Images/nomad.png';

import Skyline from '../../../Images/SkylineBG.png'
import editIcon from '../../../Images/editIcon.png';
import facebooklogo from '../../../Images/facebooklogo.png';
import twitterlogo from '../../../Images/Twitter-Logo.png';
import linkedinlogo from '../../../Images/linkedinlogo.png';
import gmaillogo from '../../../Images/Gmail-logo.png'


function PodsHeader(props) {
    return (
        <div className="pods-header">
            <div className="pods-top">
                <img src={Skyline} className="pods-bg-image" />
            </div>
            <div className="pods-bottom">
                <div className="pods-description">
                    <h2>{props.pod.name}</h2>
                    <h4>{props.pod.description}</h4>
                    <p>University of Florida</p>
                    <div className="pods-links">
                        <a><img style={{width: "13%"}} src={linkedinlogo} /></a>
                        <a><img style={{width: "20%"}} src={gmaillogo} /></a>
                        <a><img style={{width: "11%"}} src={facebooklogo} /></a>
                        <a><img style={{width: "14%"}} src={twitterlogo} /></a>
                    </div>
                </div>
                <div className="edit-pods-section" style={{paddingRight: "2%", marginTop: "2%"}}>
                    <button className="edit-pods-button">
                        <img className="edit-image" src={editIcon} />
                        Edit Profile
                    </button> 
                </div>
            </div>
            <div className="pods-avatar-holder">
                <img className="pods-avatar" src={logo}>
                </img>
            </div>
        </div>
    )
}

export default PodsHeader;
