import React, {useContext} from 'react';
import '../css/NavBar.css'
import Logo from '../../Images/hatchlogo.png'
import { AuthContext } from "../../userContext/userContext";
import { auth } from '../../firebase';
import { withRouter, Redirect } from "react-router";


//logout function


const NavBar = ({history}) => {

    const handleLogout = () => {
        auth.signOut();
    };

    const { currentUser } = useContext(AuthContext);
    if (currentUser) {
        return (
            <>
            <div className="navbar">
                <a className="logo-section" href="/" ><img src={ Logo } alt="logo" className="logo" href="/"></img><p>HATCH</p></a>
                <div className="sections">
                    <a className="navbar-section mobile-about-tab" href="/about">ABOUT</a>
                    <a className="navbar-section" href="/contactus">CONTACT</a>
                </div>
                <div className="navbar-buttons">
                    <a className="navbar-section mobile-profile-tab" href="/profile">PROFILE</a>
                    <button className="navbar-section navbar-button signup-button" onClick={handleLogout}>LOGOUT</button>
                </div>
            </div>
            </>
        );
    }
    else{
        return(
            <>
        <div className="navbar">
            <a className="logo-section" href="/"><img src={ Logo } alt="logo" className="logo" href="/"></img><p>HATCH</p></a>
            <div className="sections">
                <a className="navbar-section mobile-about-tab" href="/about">ABOUT</a>
                <a className="navbar-section" href="/contactus">CONTACT</a>
            </div>
            <div className="navbar-buttons">
                <a className="navbar-button signup-button" href="/login">LOG IN or SIGN UP</a>
            </div>
        </div>
        </>
        );
    }
    
};

export default withRouter(NavBar);