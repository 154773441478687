import React from 'react';
import '../Components/css/HomePage.css'
import Collaboration from '../Images/collaboration.svg';
import ScreenView from '../Images/screen-view.svg';
import TeamView from '../Images/team-view.svg';
import WorkTogether from '../Images/work-together.svg';
import TypistAnim from "../Components/js/typist_animation.js";
import { Container, Row, Col } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

const HomePage = () => {

    return (
    <>
        <div className="home-container">
            <div className="home-text">
                <TypistAnim />
                <div style={{height: '40px'}} />
                <p><span className="hatch-name">Hatch</span> makes it easy to connect with other passionate
                college entrepreneurs, to create your own start up team,
                and to get feedback on your start up ideas.
                </p>
                <div style={{height: '40px'}} />

                <a href={'/login'} style={{paddingLeft: '1%'}}><button className="navbar-button get-started-button">GET STARTED</button></a>
            </div>
            <div className="home-image">
                <img src={Collaboration} className="collaboration" />
            </div>
        </div>
        <div className="home-user-details">
                <div className="home-stat">
                    <i className="fa fa-user fa-lg"></i>
                    <h1>220</h1>
                    <p>USERS</p>
                </div>
                <div className="home-stat">
                    <i className="fa fa-building fa-lg"></i>
                    <h1>46</h1>
                    <p>SCHOOLS</p>
                </div>
                <div className="home-stat">
                    <i className="fa fa-rocket fa-lg"></i>
                    <h1>55</h1>
                    <p>STARTUPS</p>
                </div>
        </div>
        <div className="home-info">
                <div className="title-info"><p>Round up your <span>dream team</span>.</p></div>
                <div className="sectional-container">
                    <div className="home-sectional-top">
                        <div className="vertical-section">
                            <img src={WorkTogether} className="sectional-image work-together-image"></img>
                            <h2>Connect with People</h2>
                            <p>Search for future connections who are motivated in creating startups. Filter by skills, passion, major, etc. </p>
                            <p><a href={'/about'}>LEARN MORE</a></p>
                        </div>
                        <div className="vertical-divider"></div>
                        <div className="mobile-horizontal-divider" />
                        <div className="vertical-section">
                            <img src={ScreenView} style={{height: "50%"}} className="sectional-image"></img>
                            <h2 style={{marginTop: "-20px"}}>Create Startup Pods</h2>
                            <p>Add multiple connections into a pod to rally up your dream team and get to work! Today is <span style={{fontWeight: "bold"}}>day one</span> of something special. </p>
                            <p><a href={'/about'}>LEARN MORE</a></p>
                        </div>
                    </div>
                    <div className="home-sectional-mid">
                        <div className="horizontal-divider"></div>
                        <button className="sectional-button" style={{backgroundColor: "grey !important"}}>
                            <a href={'/signup'}>GET STARTED</a>
                        </button>
                        <div className="horizontal-divider"></div>
                    </div>
                    <div className="mobile-horizontal-divider" />
                    <div className="home-sectional-bottom">
                        <div className="horizontal-section">
                            <div className="horizontal-text">
                            <img src={TeamView} className="team-image-mobile" style={{height: "100%"}}></img>
                                <h2 style={{marginTop: "-20px"}}>ENJOY THE RIDE</h2>
                                <p>You are in control of your destiny. Meet your people and create the next big thing.</p>
                                <p><a href={'/about'}>LEARN MORE</a></p>
                            </div>
                            <img src={TeamView} className="team-image" style={{height: "100%"}}></img>
                        </div>
                    </div>
                </div>
        </div>
    </>
        
    );
}

export default HomePage;