import React, {useContext, useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import '../css/FeedComponents.css';
import { AuthContext } from "../../../userContext/userContext";
import { getAllUsers} from '../../../firebase';
import { sendFriendRequest, getRecommendedFriendsAlgo } from '../../../Components/js/GetUserData';


const RecommendedFriends =  () => {
    const [recommendedFriends, setRecommendedFriends] = useState([]);
    const [pull, setPull] = useState(true);
    const {currentUser} = useContext(AuthContext);
    
    useEffect(() => {
        if (currentUser){
            getRecommendedFriendsAlgo(currentUser, 5)
            .then(users=> {
                if (currentUser.friendRequests)
                {users.filter(user=>!currentUser.friendRequests.includes(user.uid))}
            })
            .then(users=> setRecommendedFriends(users));
        }
        setPull(false);
    }, []);
    
    const handleSend= (user, id) => {
        var tempRec = recommendedFriends.filter(userTemp => userTemp.uid !== id);
        setRecommendedFriends(tempRec);
        sendFriendRequest(user, id);
    }


    return (
        <div className="recommended-friends">
            <h5 style={{marginBottom: '5%'}}>Recommended</h5>
            {recommendedFriends && recommendedFriends.length>0 ? recommendedFriends.map((user, key) => (
                <div>
                    <Row style={{ marginBottom: '7%', alignItems: 'center'}}>
                        <Col sm={8}>
                            <a key={key} href={'/friendprofile/'+ user.uid} className="recommended-friends-row">
                                <img src={user.photoURL} className="recommended-friends-image" />
                                <div className="recommended-friends-info">
                                    <p className="recommended-friends-name">{user.firstName} {user.lastName}</p>
                                    <p style={{fontSize: '10px', marginBottom: '0%'}}>{user.college}</p>
                                </div>
                            </a>
                        </Col>
                        <Col sm={3}>
                            <button key={key} className="connect-friend-button" onClick={() => handleSend(currentUser, user.uid)}>
                                {currentUser.friendRequestsSent.includes(user.uid) ?
                                    <p style={{color: 'black', fontSize: '12px'}}>Connect</p>:
                                    <p style={{color: 'black', fontSize: '12px'}}>Sent</p>
                                }
                            </button>
                        </Col>
                    </Row>
                </div>
            )): <div>Find some friends in the search tab!</div>}
        </div>
    )
}

export default RecommendedFriends;

