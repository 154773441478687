import React, { useState, useContext, useEffect } from 'react';
import '../Portal/Components/css/FeedComponents.css'
import FeedSearchBar from '../Portal/Components/js/FeedSearchBar';
import Feed from '../Portal/Components/js/Feed';
import SideNavBar from '../Portal/Components/js/SideNavBar';
import RecommendedFriends from '../Portal/Components/js/RecommendedFriends'
import CreatePost from '../Portal/Components/js/CreatePost';
import {  AuthContext } from '../userContext/userContext';
import FriendRequests from '../Portal/Components/js/FriendRequest';

import { getAllObjectsByOrder, getObjectDocument } from '../firebase';
import BottomTabNav from '../Portal/Components/js/BottomTabNav';
import MobileNavBar from '../Portal/Components/js/MobileNavBar';



const FeedPage = () => {

    //local variables
    const {currentUser}= useContext( AuthContext );
    const [posts, setPosts] = useState([]);
    const [repull, setRepull] = useState(false);
    const [admin, setAdmin] = useState(false);

    const repullFunc = () => {
        setRepull(true);
    }


    //get all posts from firebase
    useEffect(async() => {
        const postsTemp = await getAllObjectsByOrder('posts', 10);
        console.log("get posts how quck", postsTemp);
        setPosts(postsTemp);

        
    } , [repull]);



    //get saved posts from current user
    const getSavedPosts= async () => {
        if (currentUser.savedPostIds){
            var postTemps=[];
            for (var i=0; i<currentUser.savedPostIds.length; i++){
                const postTemp = await getObjectDocument("posts",currentUser.savedPostIds[i]);
                if (postTemp){
                    postTemps.push(postTemp);
                }
            }
            setPosts(postTemps);
        }
    }


    //get posts made by my user
    const getMyPosts= async () => {
        if (currentUser.createdPostIds){
            var postTemps=[];
            for (var i=0; i<currentUser.createdPostIds.length; i++){
                const postTemp = await getObjectDocument("posts",currentUser.createdPostIds[i]);
                if (postTemp){
                    postTemps.push(postTemp);
                }
            }
            setPosts(postTemps);
        }
    }

    //get all posts by most recent
    const getPosts= async () => {
        const postsTemp = await getAllObjectsByOrder('posts', 10);
        console.log("get posts how quck", postsTemp);
        setPosts(postsTemp);
    }


    const getPostsByTopic = async (topic) => {
        var postTemps=[];
        console.log("posts by topic", topic);
        for (var i=0; i<topic.posts.length; i++){
            const postTemp = await getObjectDocument("posts",topic.posts[i]);
            if (postTemp){
                postTemps.push(postTemp);
            }
        }
            setPosts(postTemps);
        }

    
    return (
        <div className="feed-page-container">
            <div className="portal-bottom-tab-nav"> 
                <MobileNavBar />
            </div>
            <div className="feed-page-container-left">
                <SideNavBar currentUser={currentUser} />
            </div>
            <div className="feed-page-container-middle">
                <FeedSearchBar getPostsByTopic={getPostsByTopic} />
                <div className="post-types">
                    <div className="post-types-left">
                        <button onClick={()=>getPosts()} className="post-types-button" autoFocus>All Posts</button>
                        <button onClick={()=>getSavedPosts()} className="post-types-button">Saved Posts</button>
                        <button onClick={()=>getMyPosts()} className="post-types-button">My Posts</button>
                    </div>
                    <div style={{width: '25%', marginRight: '1%'}}>
                        <CreatePost repull={repullFunc} currentUser={currentUser} />
                    </div>
                </div>
                {posts.length > 0 ? <Feed posts={posts} currentUser={currentUser} repull={repullFunc} /> : <div >No Posts!</div>}
            </div>
            <div className="feed-page-container-right" >
                <RecommendedFriends />
                <br></br>
                <FriendRequests />
            </div>

            
        </div>

    )
}

export default FeedPage;
