import React, {useContext, useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import users from '../../../Data/userdata';
import '../css/FeedComponents.css';
import { AuthContext } from "../../../userContext/userContext";
import { acceptFriendRequest, getUsersByIDs } from '../../../Components/js/GetUserData';


const FriendRequests =  () => {
    const [friendRequests, setFriendRequests] = useState([]);
    const [pull, setPull] = useState(true);
    const {currentUser} = useContext(AuthContext);
    
    useEffect(async () => {
        if (currentUser.friendRequests){
            if (currentUser.friendRequests.length > 0){
                const requests=await getUsersByIDs(currentUser.friendRequests);
                setFriendRequests(requests);
            }
        }
        setPull(false);
    }, [currentUser.friendRequests]);
    

    const handleSend= (user, id) => {
        var temp= friendRequests.filter(user=> user.uid !== id);
        setFriendRequests(temp);
        acceptFriendRequest(user, id);
    }

    return (
        <div className="recommended-friends">
            <h4 style={{marginBottom: '5%'}}>Friend Requests</h4>
            {friendRequests.length>0 ? friendRequests.map((user, key) => (
                <div>
                    <Row style={{ marginBottom: '7%', alignItems: 'center'}}>
                        <Col sm={8}>
                            <a key={key} className="recommended-friends-row">
                                <img src={user.photoURL} className="recommended-friends-image" />
                                <div className="recommended-friends-info">
                                    <p className="recommended-friends-name">{user.firstName} {user.lastName}</p>
                                    <p style={{fontSize: '10px', marginBottom: '0%'}}>{user.college}</p>
                                </div>
                            </a>
                        </Col>
                        <Col sm={4}>
                            <button key={key} className="connect-friend-button" onClick={()=>handleSend(currentUser, user.uid)}>
                                <p style={{color: 'black', fontSize: '12px'}}>Accept</p>
                            </button>
                        </Col>
                    </Row>
                    
                </div>
            )): <p>No Friend Requests</p>}
        </div>
    )
}

export default FriendRequests;
