import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import { Redirect } from "react-router";


require('dotenv').config()

//fire base config 
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
  };
  


firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();


//sign in with google
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
    return new Redirect('/additionalData');
  };


  //create user infomation
export const generateUserDocument = async (user, additionalData) => {
    if (!user) return ; 
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
  };

  //get user data
  export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get();
      return {
        uid,
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };



  //upload a file to firebase for a user
  export const handleUserUpload = (user, folder,file, name) =>{
    if (!user) return ; 
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = storageRef.child(folder + "/"+ user.uid +"/"+ name).put(file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>{
        var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100;
      },(error) =>{
        throw error;
      },() =>{
        uploadTask.snapshot.ref.getDownloadURL().then(async (url) =>{
          var jsonData = {};
          var temp= name+"URL"
          jsonData[temp] = url;
          
            
            const userRef = firestore.doc(`users/${user.uid}`);
            const snapshot = await userRef.get();
            if (snapshot.exists) {
            userRef.update(jsonData);
          }
           console.log(jsonData);
        });
  
     }
   ) 

  }



  //update user doc
  export const updateFirebaseUser= async (user,additionalData) => {
          if (!user) return ;
            const userRef = firestore.doc(`users/${user.uid}`);
            const snapshot = await userRef.get();
          if (snapshot.exists) {
            userRef.update(additionalData);
          }
          console.log(additionalData);
}



//create pod
export const generatePodDocument = async (user, data) => {
  if (!user || !data) return ; 
  const podRef = firestore.collection('pods').doc();
  const snapshot = await podRef.get();
  if (!snapshot.exists) {
    try {
      await podRef.set({
        ...data
      });
    } catch (error) {
      console.error("Error creating Pod document", error);
    }
  }
  if (!user.pods) user.pods = [];
  user.pods.push(snapshot.id);
  updateFirebaseUser(user,{pods:user.pods});

  return getPodDocument(snapshot.id);
};


//get pod data
export const getPodDocument = async id => {
  if (!id) return null;
  try {
    const podDocument = await firestore.doc(`pods/${id}`).get();
    return {
      id,
      ...podDocument.data()
    };
  } catch (error) {
    console.error("Error fetching Pod", error);
  }
};



//get all users

export const getAllUsers= async() => {
  const users = await firestore.collection('users');
  var temp=[];
  await users.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         temp.push({ id: doc.id, ...doc.data() });
      })
      console.log(temp);
   })
   return temp;
 }





 //make a chat room
 export const generateChatRoomDocument = async (user, otherUser) => {
  if (!user || !otherUser) return ; 
  if (otherUser.chatRooms){
    const roomId= user.chatRooms.find(room => otherUser.chatRooms.includes(room));
    if (roomId) {
      return console.log("already chat room with both users");
    }}
  const podRef = firestore.collection('chatRooms').doc();
  const snapshot = await podRef.get();
  if (!snapshot.exists) {
    try {
      await podRef.set({
        type: "User Chat",
        users: [user.uid, otherUser.uid]
      });
    } catch (error) {
      console.error("Error creating ChatRoom document", error);
    }
  }
  user.chatRooms.push(snapshot.id);
  otherUser.chatRooms.push(snapshot.id);
  updateFirebaseUser(user,{chatRooms:user.chatRooms});
  updateFirebaseUser(otherUser,{chatRooms:otherUser.chatRooms});

  return getChatRoomDocument(snapshot.id);
};


//get chat room data
export const getChatRoomDocument = async id => {
  if (!id) return null;
  try {
    const chatDocument = await firestore.doc(`chatRooms/${id}`).get();
    if (chatDocument.exists){
      return {
        id,
        ...chatDocument.data()
      };
    }
    else{
      return null;
    }
  } catch (error) {
    console.error("Error fetching Chat Room", error);
  }
};



//add a friend
export const addFriend=async (user, otherUserID) => {
  const otherUser=await getUserDocument(otherUserID)
  if (!user || !otherUser){ console.log("no user"); return ;} 
  if (!user.friends) user.friends=[];
  if (!otherUser.friends) otherUser.friends=[];
  if (user.friends.includes(otherUser.uid)){
    if (otherUser.friends.includes(user.uid)){
      return;
    }
    else{
      otherUser.friends.push(user.uid);
      updateFirebaseUser(otherUser,{friends:otherUser.friends});
    }
  }
  user.friends.push(otherUser.uid);
  otherUser.friends.push(user.uid);
  updateFirebaseUser(user,{friends:user.friends});
  updateFirebaseUser(otherUser,{friends:otherUser.friends});
  return user;
};



//create a pod chat room
export const generatePodChatRoomDocument = async (pod, name) => {
  if (!pod) return ; 
  const podRef = await firestore.collection('chatRooms').doc();
  const snapshot = await podRef.get();
  const admins=pod.admins;
  const regularUsers=pod.regularUsers;
  if (!pod.chatRooms){
    pod.chatRooms = [];
  }
  


  if (!snapshot.exists) {
    try {
     await podRef.set({
        name: name,
        type: "Pod Chat",
        admins: admins,
        regularUsers: regularUsers,
        recentMsg: '',
        podID: pod.id
      });
      
      
    } catch (error) {
      console.error("Error creating ChatRoom document", error);
    }
  }
  


  pod.chatRooms.push(snapshot.id);
  await updateFirebasePod(pod,{chatRooms:pod.chatRooms});

  return getChatRoomDocument(snapshot.id);
};


//update pod data
export const updateFirebasePod = async (pod,additionalData) => {
  if (!pod) return ;
    const podRef = firestore.doc(`pods/${pod.id}`);
    const snapshot = await podRef.get();
  if (snapshot.exists) {
    podRef.update(additionalData);
  }
  console.log(snapshot);
}


//update chat general data
export const updateFirebaseChat= async (chat,additionalData) => {
  if (!chat) return ;
    const userRef = firestore.doc(`chatRooms/${chat.id}`);
    const snapshot = await userRef.get();
  if (snapshot.exists) {
    userRef.update(additionalData);
  }
  console.log(additionalData);
}



//upload a file to firebase for a pod
export const handlePodUpload = (pod, folder,file, urlName) =>{
  if (!pod) return ; 
  var storage = firebase.storage();
  var storageRef = storage.ref();
  var uploadTask = storageRef.child(folder + "/" + pod.id + "/" + file.name).put(file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) =>{
      var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100;
    },(error) =>{
      throw error;
    },() =>{
      uploadTask.snapshot.ref.getDownloadURL().then(async (url) =>{
        var jsonData = {};
        jsonData[urlName] = url;
          
          const userRef = firestore.doc(`pods/${pod.id}`);
          const snapshot = await userRef.get();
          if (snapshot.exists) {
          userRef.update(jsonData);
        }
         console.log(jsonData);
      });

   }
 ) 

}


//create an object (template)
export const generateObjectDocument = async (folderName, data) => {
  if (!folderName || !data) return ; 
  console.log(data);
  const ref = await firestore.collection(folderName).doc();
  const snapshot = await ref.get();
  if (!snapshot.exists) {
    try {
      await ref.set({
        ...data
      });
    } catch (error) {
      console.error("Error creating document", error);
    }
  }
  

  return await getObjectDocument(folderName, snapshot.id);
};



//get an object (template)
export const getObjectDocument = async (folderName, id) => {
  if (!id) return null;
  try {
    const document = await firestore.doc(`${folderName}/${id}`).get();
    return {
      id,
      ...document.data()
    };
  } catch (error) {
    console.error("Error fetching document", error);
  }
};


export const deleteObjectDocument = async (folderName, id) => {
  if (!id) return null;
  try {
    const documentRef = await firestore.doc(`${folderName}/${id}`);
    return documentRef.delete();
  } catch (error) {
    console.error("Error fetching document", error);
  }
};


//get all objects (templates)
export const getAllObjects = async (folderName, limit) => {
  var objects= await firestore.collection(folderName).limit(limit);
  var temp=[];
  await objects.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         temp.push({ id: doc.id, ...doc.data() });
      })
      console.log(temp);
   })
   return temp;
};

  
export const getAllObjectsByOrder = async (folderName, limit) => {
  var objects= await firestore.collection(folderName).orderBy("createdTime").limitToLast(limit);
  var temp=[];
  await objects.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         temp.push({ id: doc.id, ...doc.data() });
      })
      console.log(temp);
   })
   return temp;
};
  
  export const getAllObjectsNoLimit = async (folderName) => {
  var objects= await firestore.collection(folderName);
  var temp=[];
  await objects.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
         temp.push({ id: doc.id, ...doc.data() });
      })
      console.log(temp);
   })
   return temp;
};


//update object data
export const updateFirebaseObject = async (folderName,id,additionalData) => {
  if (!id || !folderName || !additionalData) return ;
    const ref = firestore.doc(`${folderName}/${id}`);
    const snapshot = await ref.get();
    if (snapshot.exists) {
      ref.update(additionalData);
    }
  console.log(snapshot);
}

