import React from 'react';
import '../Components/css/AboutPage.css';
import onlineChat from '../Images/onlineChat.svg'
import browse from '../Images/browse.svg'
import workChat from '../Images/workChat.svg'
import aboutTeam from '../Images/aboutTeam.svg'
import Faqs from '../Components/js/Faqs';
import RyanImage from '../Images/RyanShear.png'
import MattImage from '../Images/MattHanson.png'
import DylanImage from '../Images/DylanTarlowe.JPG'

const AboutPage = () => (
    <>
    <div className="about-container">
        <div className="landing"> 
            <div className="landing-left">
                <div className="landing-header">
                    <h1>About</h1>
                    <span className="line-after"><h1>Hatch</h1></span>
                </div>
                <div className="landing-text">
                    <p >
                    Our vision is to become the hub where college students can seamlessly connect with 
                    other like-minded peers, whether entrepreneurs, determined innovators, or future partners. 
                    We strive to find the missing piece to your puzzle, whether it be an idea or a team! 
                    Build your profile and connect with others who want to create the next big thing.
                    </p>
                </div>
                <a href={'/login'}><button className="signup-free-button">SIGN UP FREE</button></a>
            </div>
            <div className="landing-right" >
                <img src={aboutTeam} style={{width: "70%", backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}className="landing-right-image"/>
            </div>
        </div>

        <div className="why-omelette-container">
            <br /> <br />
            <h3>Why Hatch?</h3>
            <div className="why-omelette">
                <div className="why-omelette-columns">
                    <img src={onlineChat} className="column-image"/>
                    <h5>Meet like-minded people</h5>
                    <p>Search by location, passion, major, etc.</p>
                </div>
                <div className="mobile-horizontal-divider" />
                <div className="why-omelette-columns" >
                    <img src={browse} className="column-image"/>
                    <h5>Browse ideas</h5>
                    <p>Look at existing ideas. Join any one that interests you.</p>
                </div>
                <div className="mobile-horizontal-divider" />
                <div className="why-omelette-columns" >
                    <img src={workChat} className="column-image"/>
                    <h5>Work on <span>your</span> passion</h5>
                    <p>Work on what you want to work on.
                        We filter your potential business idea by passion and find people with as much fire in them as you.
                    </p>
                </div>
            </div>
        </div>
        <div className="faqs-container">
            <Faqs />
        </div>
        <div className="our-story-container">
            <h3>About Us</h3>
            <div className="our-story">
                <div className="our-story-column">
                    <img src={DylanImage} className="our-story-image"/>
                    <h5>Dylan Tarlowe</h5>
                    <p>
                        Hello! My name is Dylan Tarlowe, and I am studying computer science at the University of Florida.
                        Throughout college, I have always wanted to create that next big thing, but I had no business idea. Through mutual friends, 
                        Matt introduced me to Ryan, who pitched me Hatch. I found it ironic that Ryan was pitching me a way
                        to get out of the exact situation I was in: no business idea and no startup team. 
                    </p>
                </div>
                <div className="our-story-column">
                    <img src={RyanImage} className="our-story-image"/>
                    <h5>Ryan Shear</h5>
                    <p>
                        Hey guys! My name is Ryan Shear, and I am currently an accounting major at the University of Florida. 
                        Hatch originated because I've always had an interest in the startup industry
                        but didn't know how to find other students at my school who shared this interest. 
                        So one day, I decided to jot down an idea on a google doc about connecting like-minded people 
                        and pitched it to my friend Matt. He then reached out to Dylan, and before we knew it, we had the A-team. 
                    </p>
                    <p>
                        Some other fun facts about me: 
                        I love running, working out, snorkeling, fishing, chess, watching movies (superhero movies!),
                        food, networking, traveling, and staying busy...I cannot have downtime!
                    </p>
                </div>
                <div className="our-story-column">
                    <img src={MattImage} className="our-story-image"/>
                    <h5>Matt Hanson</h5>
                    <p>
                        Hi Everyone!! 
                        My name is Matt Hanson and I am currently a senior computer science student 
                        at the University of Florida. Since I wrote my first line of code about 2 years ago, 
                        I have immersed myself into the world of technology. 
                        When Ryan pitched me this idea a couple of months ago, 
                        I thought it was a great idea but did not know the first thing about starting a startup. 
                        I then reached out to Dylan and we have made a great team since then.
                    </p>
                    <p>
                        I have a lifelong passion for nutrition and fitness that I have been pursuing 
                        since middle school. My other passions and hobbies include movies, coffee, podcasts, 
                        and of course technology.
                    </p>
                </div>
            </div>
        </div>

    </div>

    </>

)

export default AboutPage;