import React, {useState, useContext, useCallback, useEffect} from 'react';
import { withRouter, Redirect } from "react-router";
import {Link} from 'react-router-dom';
import { Form, Button, Modal} from 'react-bootstrap';
import Popup from 'reactjs-popup';
import '../css/PodsComponents.css'
import { AuthContext } from "../../../userContext/userContext";
import {generateObjectDocument, getObjectDocument, updateFirebasePod, updateFirebaseUser} from "../../../firebase";
import firebase from "firebase/app"




function NewPodPost(props) {


    //local variables
    const [pod, setPod] = useState({});
    const [posts, setPosts] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [form, setForm] = useState({description: '', positionsWanted:''});
    const [redirect, setRedirect] = useState(false);
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    //set function
    const set = name => {
        return ({ target: { value } }) => {
          setForm(oldValues => ({...oldValues, [name]: value }));
        }
      };

      //where pop up shows up
    const anchorConfig={
        horizontal: "center",
        vertical: "middle",
    };


    useEffect(async () => {
        //set pod
        if (props.pod){
            await setPod(props.pod);
        }

        //set posts
        if(props.postIDs && props.postIDs.length > 0){
            var temp=[];
            for (let i = 0; i < props.postIDs.length; i++) {
                const post = await getObjectDocument('posts',props.postIDs[i]);
                temp.push(post);
            }
            setPosts(temp);
        }
    }, []);

    //debugging
    console.log("debug posts",posts);
    console.log("POD", pod);


    //handle form submission
    const handleSubmit = async () => {
    
        const description= document.getElementById("text-post").value;
        const positionsWanted= document.getElementById("positions-wanted").value;
        const interested=[];
        const numberOfLikes=0;
        const numberOfDislikes=0;
        const type='Positions Wanted';
        const createdByID=currentUser.uid;
        console.log("undef", pod);
        const podID=pod.id;
        console.log("undef", podID);

        //set the date
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + '/' + dd + '/' + yyyy;
        const createdAt=today;
        const createdTime = firebase.firestore.FieldValue.serverTimestamp();

        
        

        const post = await generateObjectDocument('posts',{description, positionsWanted, interested, numberOfDislikes, numberOfLikes, type, createdByID, podID, createdAt, createdTime});
        console.log("post firebase create", post);
        const postId = post.id;
                    //add post to pod object
            if (post && postId){
                console.log("post firebase create", post);
                var newPostIDs=pod.postIDs;
                if (newPostIDs) {
                    newPostIDs.push(post.id);
                    console.log("newPostIDs",newPostIDs);
                } 
                else {
                    newPostIDs=[];
                    newPostIDs.push(post.id);
                }
                updateFirebasePod(pod,{postIDs:newPostIDs});
                var createdPostIds=[];
                if (currentUser.createdPostIds){
                    createdPostIds=currentUser.createdPostIds;
                }
                createdPostIds.push(postId);
                updateFirebaseUser(currentUser, {createdPostIds});
                
                var tempArr=posts;
                tempArr.push(post);
                setPosts(tempArr);
            }

            
            //reset form
            document.getElementById("text-post").value="";
            document.getElementById("positions-wanted").value="";
            handleClose();
    };

        //redirect or show form
        if (redirect) {
            return <Redirect to={"/feed"} />
        }
        else{
            return (
                <div className="pod-posts-container">
                    <div className="pods-posts-header">
                        <h4>Posts</h4>
                        <button className="add-pod-post-button" onClick={handleShow}>
                            Create a Post
                        </button>
                    </div>

                    {posts.length > 0 ? posts.map((post,key) => 
                    (
                        <div key={key} className="pod-post-row">
                            <div className="pod-description">{post.description}</div>
                            <div className="pod-rules">{post.positionsWanted}</div>
                        </div>
                    )) : <div>No Posts yet</div>}
                    


                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header style={{background: '#fbfaf5'}} >
                        <Modal.Title>Create a Post</Modal.Title>
                        <closeButton />
                        </Modal.Header>
                        <Modal.Body style={{background: '#fbfaf5'}}>
                            <textarea 
                                id="text-post"
                                rows="5"
                                cols="40" 
                                placeholder="Write your post"  
                                className="create-post-input" 
                                required
                            />
                            <input 
                                className="roles-wanted-input"
                                id="positions-wanted"
                                placeholder="Roles needed" 
                            />
                        </Modal.Body>
                        <Modal.Footer style={{background: '#fbfaf5'}}>
                            <button className="add-post-button" onClick={handleSubmit}>
                                Post
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>

            )
        }
        }

export default withRouter(NewPodPost);