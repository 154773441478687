import React from 'react';
import { Row, Col} from 'react-bootstrap';
import { HiOutlineMail, HiOutlineUserGroup } from 'react-icons/hi';
import { IoCalendarOutline, IoSchoolOutline } from 'react-icons/io5';
import {updateFirebaseUser} from '../../../firebase';

export default function AdditionalInfo({currentUser}) {
    console.log('AdditionalInfo', currentUser);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const createdAt= today;

    if (!currentUser.createdAt) {
        currentUser.createdAt = today;
        updateFirebaseUser(currentUser, {createdAt});
    }

    return (
        <div className="additional-section">
            <h4 style={{marginBottom: "5%"}}>Additional Info</h4>
            <div style={{paddingLeft: "5%"}}>
            <Row style={{marginBottom: "2%"}}>
                <Col sm={1}>
                    <HiOutlineMail style={{height: "75%" }} />
                </Col>
                <Col>
                    <p style={{marginBottom: "2%"}}>EMAIL</p>
                    <p style={{color: "#ecc238"}}>{currentUser.email}</p>
                </Col>
            </Row>
            <Row style={{marginBottom: "2%"}}>
                <Col sm={1}>
                    <IoSchoolOutline style={{height: "75%"}} />
                </Col>
                <Col>
                    <p style={{marginBottom: "2%"}}>MAJOR</p>
                    <p style={{color: "#ecc238"}}>{currentUser.major ? currentUser.major : "Let us know your major!"}</p>
                </Col>
            </Row>
            <Row style={{marginBottom: "2%"}}>
                <Col sm={1}>
                    <HiOutlineUserGroup style={{height: "75%"}} />
                </Col>
                <Col>
                    <p style={{marginBottom: "2%"}}>NETWORK</p>
                    <p style={{color: "#ecc238"}}>{currentUser.friends.length} connections</p>
                </Col>
            </Row>
            <Row style={{marginBottom: "2%"}}>
                <Col sm={1}>
                    <IoCalendarOutline style={{height: "75%"}} />
                </Col>
                <Col>
                    <p style={{marginBottom: "2%"}}>JOIN DATE</p>
                    <p style={{color: "#ecc238"}}>{currentUser.createdAt ? currentUser.createdAt : createdAt}</p>
                </Col>
            </Row>
            </div>
        </div>
    )
}
