import React, {useState} from 'react';
import '../css/Navbars.css';
import { IoChatbox, IoNewspaper, IoMoonOutline, IoHome, IoSearch, IoPeople, IoPerson, IoMenuSharp } from 'react-icons/io5';

export default function MobileNavBar({currentUser}) {
    const [showNav, setShowNav] = React.useState(false);

    return (
        <div>
        <div className="mobile-nav-bar-container">

            { !showNav &&
                <button className="mobile-nav-toggle-button" onClick={() => setShowNav(!showNav)}>
                    <IoMenuSharp style={{width: "200%", height: "200%"}} />
                </button>
            }
            { showNav &&
                <button className="mobile-nav-toggle-button" onClick={() => setShowNav(!showNav)}>
                    <IoMenuSharp style={{width: "200%", height: "200%"}} />
                </button>
            }
        </div>
        { showNav  &&
            <div className="mobile-tab-nav">
                <div className="mobile-tab-icon-group" >            
                    <button className="mobile-tab-nav-link">
                        <a href={'/feed'}>
                            <IoHome style={{height: '25px'}} className="mobile-tab-icon-nav"/>
                            <p>Home</p>
                        </a>
                    </button>
                    <button className="mobile-tab-nav-link">
                        <a href={'/messages'}>
                            <IoChatbox style={{height: '25px'}} className="mobile-tab-icon-nav"/>
                            <p>Messages</p>
                        </a>
                    </button>
                    <button className="mobile-tab-nav-link">
                        <a href={'/network'}>
                            <IoPeople style={{height: '25px'}} className="mobile-tab-icon-nav"/>
                            <p>Network</p>
                        </a>
                    </button>
                    <button className="mobile-tab-nav-link">
                        <a href={'/search'}>
                            <IoSearch style={{height: '25px'}} className="mobile-tab-icon-nav"/>
                            <p>Search</p>
                        </a>
                    </button>
                    <button className="mobile-tab-nav-link">
                        <a href={'/profile'}>
                            <IoPerson style={{height: '25px'}} className="mobile-tab-icon-nav"/>
                            <p>Profile</p>
                        </a>
                    </button>
                </div>
            </div> 
            }
        </div>
    )
}

