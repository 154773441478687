import React, { useState, useContext, useEffect } from 'react';
import '../Components/css/SignupPage.css';
import { getPodDocument, handleUserUpload, updateFirebaseUser, getAllObjectsNoLimit, getObjectDocument} from '../firebase';
import { addCollege, addLocation, addRole} from '../Components/js/GetUserData';
import { Form, Button, Row, Col, Container, Jumbotron } from 'react-bootstrap';
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "../userContext/userContext";
import {removeUserFile} from '../Components/js/DeleteFunctions';
import CreatableSelect from 'react-select/creatable';
import { IoNavigateCircleOutline } from 'react-icons/io5';

const AdditionalDataPage = ({history}) => {
  

    //hold values for form


    const { currentUser } = useContext(AuthContext);

    console.log(currentUser);

    const [formSub, setFormSub] = useState(false);
    const [loading,setLoading] = useState(false);

    const [collegeOptions, setCollegeOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);


    const [collegeForm, setCollegeForm] = useState(null);
    const [locationForm, setLocationForm] = useState(null);
    const [currentRoleForm, setCurrentRoleForm] = useState(null);

    const [sign, setSign] = useState({ 
        ...currentUser
      });
      
    
      const set = name => {
        return ({ target: { value } }) => {
          setSign(oldValues => ({...oldValues, [name]: value }));
        }
      };

      useEffect(async () => {
        var collegeTemp={};
        if (currentUser.collegeId){
          collegeTemp = await getObjectDocument("colleges",currentUser.collegeId);
          await setCollegeForm(collegeTemp);
        }

        if (currentUser.roleId){
          var roleTemp = await getObjectDocument("roles",currentUser.roleId);
          await setCurrentRoleForm(roleTemp);
        }

        if (currentUser.locationId){
          var locationTemp = await getObjectDocument("locations",currentUser.locationId);
          await setLocationForm(locationTemp);
        }

        var ops= await getAllObjectsNoLimit("colleges");

        var optionsRol= await getAllObjectsNoLimit("roles");
        var optionsLoc= await getAllObjectsNoLimit("locations");

        setCollegeOptions(ops);
        setRoleOptions(optionsRol);
        setLocationOptions(optionsLoc);

      }, [formSub]);


      const handleChangeCollege=(newValue, actionMeta)=>{
        console.log(newValue);
        setCollegeForm(newValue);
      }

      const handleChangeRole=(newValue, actionMeta)=>{
        console.log(newValue);
        setCurrentRoleForm(newValue);
      }
      const handleChangeLocation=(newValue, actionMeta)=>{
        console.log(newValue);
        setLocationForm(newValue);
      }


      //sign up with firebase
      const handleUpdate = async event => {
        event.preventDefault();
        setLoading(true);
       
        
        const location= locationForm.value;
        const firstName= await event.target.firstName.value.toString();
        const lastName= await event.target.lastName.value.toString();
        const username= "";
        const college= collegeForm.value;
        const currentRole= currentRoleForm.value;
        const major= await event.target.major.value.toString()? event.target.major.value.toString(): "";


        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        var createdAt = today;
        
        var pods=[];
        var friends=[];
        var aboutMe="";
        var chatRooms=[];
        if (currentUser.pods){
          pods=currentUser.pods;
        }
        if (currentUser.friends){
          friends=currentUser.friends;
        }
        if (currentUser.aboutMe){
          aboutMe=currentUser.aboutMe;
        }
        if (currentUser.chatRooms){
          chatRooms=currentUser.chatRooms;
        }
        if (currentUser.createdAt){
          createdAt=currentUser.createdAt;
        }


        
        try {
          if (currentUser.createdAt){
            await updateFirebaseUser(currentUser, {major, location, firstName, lastName, username, pods, friends, currentRole, aboutMe, chatRooms, createdAt, college});}
          else{
            await updateFirebaseUser(currentUser, {major, location, firstName, lastName, username, pods, friends, currentRole, aboutMe, chatRooms, college});
          }

          
          await addCollege(currentUser, collegeForm);
          await addRole(currentUser, currentRoleForm);
          await addLocation(currentUser, locationForm);
          console.log(currentUser);
          setFormSub(true);
          setLoading(false);


    

        /* if (event.target.resume && event.target.resume.files[0]) {
          if (currentUser.resumeURL){
            removeUserFile(currentUser, 'userFiles', 'resume');
          }
          handleUserUpload(currentUser,"userFiles",event.target.resume.files[0], "resume");
        } */

          
        } catch (error) {
          alert(error);
          setLoading(false);
        }
      };


      

      //get current user
      if (loading){
        return <div>Loading ...</div>
      }
      
      if (formSub){
        if (!currentUser.firstName){
          window.location.href="/profile";
          return <div>Loading ...</div>
        }
        else{
          return <Redirect to='/profile'/>;
        }
      }
      
      if (!currentUser && !formSub) {
        return  <Redirect to='/login'  />;
      }
      else{
        return (
            <>
            
            <div className="signup-container">
            <br />
                <Container>
                <Row>
                        <Col sm={5} style={{ textAlign: 'left', paddingTop: '10%', paddingLeft: '60px'}}>
                            <h1 className="signup-text">Hey we need some more info to better personalize your experience!</h1>  
                        </Col>

                        <Col sm={7}  >
                        <br></br>
                            <h1 className="new-account-text">Additional Info</h1>

                            <Form onSubmit={handleUpdate} style={{ textAlign: 'left'}} >

                                <Form.Group controlId="validationFormik01">
                                    <Form.Label className="form-label-text">First name</Form.Label>
                                    <Form.Control name="firstName" required className="form-label-text"type="text" placeholder="First Name" value={sign.firstName } onChange={set('firstName')}/>
                                </Form.Group>

                                <Form.Group controlId="validationFormik02">
                                    <Form.Label className="form-label-text">Last Name</Form.Label>
                                    <Form.Control name="lastName" required className="form-label-text" type="text" placeholder="Last Name" value={sign.lastName} onChange={set('lastName')}/>
                                </Form.Group>

                                <Form.Group controlId="validationFormik02">
                                    <Form.Label className="form-label-text">Major</Form.Label>
                                    <Form.Control name="major" className="form-label-text" type="text" placeholder="Your Major" value={sign.major} onChange={set('major')}/>
                                </Form.Group>


                                

                                <Form.Group controlId="formGridCity">
                                    <Form.Label className="form-label-text">City</Form.Label>
                                    <CreatableSelect
                                      isClearable
                                      onChange={handleChangeLocation}
                                      options={locationOptions}
                                      value={locationForm}
                                      placeholder="Select or Create your City"
                                      required
                                    />
                                    <Form.Control tabIndex={-1}
                                        autoComplete="off" required style={{ opacity: 0, height: 0 }} value={locationForm? 'test' : ''} />
                                </Form.Group>

                                <Form.Group controlId="validationFormik02">
                                    <Form.Label className="form-label-text">College</Form.Label>
                                    
                                    <CreatableSelect
                                      isClearable
                                      onChange={handleChangeCollege}
                                      options={collegeOptions}
                                      value={collegeForm}
                                      placeholder="Select or Create your College"
                                      required
                                    />
                                    <Form.Control tabIndex={-1}
                                        autoComplete="off" required style={{ opacity: 0, height: 0 }} value={collegeForm? 'test' : ''} />
                                    

                                </Form.Group>
                                
                                <Form.Group controlId="validationFormik02">
                                    <Form.Label className="form-label-text">Current Role or Desired Role</Form.Label>
                                    
                                    <CreatableSelect
                                      isClearable
                                      onChange={handleChangeRole}
                                      options={roleOptions}
                                      value={currentRoleForm}
                                      placeholder="Select or Create your Current or Desired Job"
                                      required
                                    />
                                    <Form.Control tabIndex={-1}
                                        autoComplete="off" required style={{ opacity: 0, height: 0, position:'absolute', size:0 }} value={currentRoleForm? 'test' : ''} />
                                </Form.Group>
                                <br></br>

                                

                                <br></br>

                                <button className="signup-button navbar-button" type="submit">
                                    Submit Information
                                </button>

                            </Form>
                            <br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
            </>
        );}
}

export default withRouter(AdditionalDataPage);