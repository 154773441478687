import { getUserDocument, getChatRoomDocument, updateFirebasePod, updateFirebaseUser,  generateObjectDocument, getObjectDocument, updateFirebaseObject, deleteObjectDocument, storage } from "../../firebase"


export const removeFriend = async (currentUser, friendId) =>{

    //error checking
    if (!currentUser, !friendId){ return false;}
    if (!currentUser.uid) return false;
    
    

    //get other user
    var otherPerson=await getUserDocument(friendId);

    

    
    //remove friends
    if (currentUser.friends){
        var friends=currentUser.friends.filter(id=> id != friendId);
        await updateFirebaseUser(currentUser, {friends});
    }

    if (otherPerson.friends){
        var friends=otherPerson.friends.filter(id=> id != currentUser.uid);
        await updateFirebaseUser(otherPerson, {friends});
    }


    //error checking after removing friend in case friend only in one user
    if (!otherPerson) return false;
    if (!otherPerson.friends){return false;};
    if (!currentUser.friends){return false;};

    //if all good return true
    return true;
}

export const deletePost= async (currentUser, postObject)=>{
    console.log(postObject);
    if (!currentUser || !postObject) return false;
    if (!currentUser.uid || !postObject.id) return false;

    if (currentUser.createdPostIds){
        var createdPostIds= currentUser.createdPostIds.filter(id => id !== postObject.id);
        await updateFirebaseUser(currentUser, {createdPostIds});
    }

    if (postObject.comments){
        for (var i=0; i<postObject.comments.length; i++){
            const comment= postObject.comments[i];
            var user = await getUserDocument( comment.user);
            if (user.createdPostIds){
                var commentedPostIds= user.commentedPostIds.filter(id => id !== postObject.id);
                await updateFirebaseUser(user, {commentedPostIds});
            }
        }
    } 

    if (postObject.interested){
        for (var i=0; i<postObject.interested.length; i++){
            const interested= postObject.interested[i];
            var user = await getUserDocument( interested.user);
            var interestedPostIds = user.interestedPostIds.filter(id => id !== postObject.id);
            await updateFirebaseUser(user, {interestedPostIds});
        }
    }

    if (postObject.savedBy){
        for (var i=0; i<postObject.savedBy.length; i++){
            const savedBy= postObject.savedBy[i];
            var user = await getUserDocument( savedBy);
            var savedPostIds = user.savedPostIds.filter(id => id !== postObject.id);
            await updateFirebaseUser(user, {savedPostIds});
        }

    }

    if (postObject.topics){
        for (var i=0; i<postObject.topics.length; i++){
            var topicId= postObject.topics[i].id;
            await removeTopic(postObject, topicId);
        }
    }


    await deleteObjectDocument('posts', postObject.id);

    return true;

}


export const removeInterest = async (currentUser, interestId)=>{
    if (!currentUser || !interestId) return false;
    if (!currentUser.uid) return false;

    if (currentUser.interests){
        var interests= currentUser.interests.filter(interest => interest.id !== interestId);
        await updateFirebaseUser(currentUser, {interests});
    }

    const interest = await getObjectDocument("interests", interestId);
    if (!interest) return false;
    if (interest.users){
        var users= interest.users.filter(id => id != currentUser.uid);
        await updateFirebaseObject("interests",interest.id, {users});
    }

    return true;
}

export const removeSkill = async (currentUser, skillId)=>{
    if (!currentUser || !skillId) return false;
    if (!currentUser.uid) return false;

    if (currentUser.skills){
        var skills= currentUser.skills.filter(skill => skill.id !== skillId);
        await updateFirebaseUser(currentUser, {skills});
    }

    const skill = await getObjectDocument("skills", skillId);
    if (!skill) return;
    if (skill.users){
        var users= skill.users.filter(id => id != currentUser.uid);
        await updateFirebaseObject("skills",skill.id, {users});
    }
    
    return true;
}


export const deletePod = async (currentUser, podObject)=>{

    //error checking
    if (!currentUser || !podObject) return false;
    if (!currentUser.uid) return false;
    if (!podObject.admins.includes(currentUser.uid)){
        return false;
    }

    //delete posts and chat rooms
    if (podObject.posts){
        for (var i=0; i< podObject.postIDs.length; i++){
            const post = await getObjectDocument("posts", podObject.postIDs[i]);
            await deletePost(currentUser, post);
        }
    }

    if (podObject.chatRooms){
        for (var i=0; i< podObject.chatRooms.length; i++){
            await deleteChatRoom(currentUser, podObject.chatRooms[i]);
        }
    }


    //remove pod from user
    if (podObject.admins){
        for (var i=0; i< podObject.admins.length; i++){
            const admin= await getUserDocument(podObject.admins[i]);
            var pods= admin.pods.filter(id => id !== podObject.id);
            await updateFirebaseUser(admin, {pods});
        }
    }
    if (podObject.regularUsers){
        for (var i=0; i< podObject.regularUsers.length; i++){
            const user= await getUserDocument(podObject.regularUsers[i]);
            var pods= user.pods.filter(id => id !== podObject.id);
            await updateFirebaseUser(user, {pods});
        }
    }

   

    //remove pod from firebase
    await deleteObjectDocument('pods', podObject.id);

    return true;
}


export const deleteChatRoom = async (currentUser, chatRoomId)=>{
    if (!currentUser || !chatRoomId) return false;
    if (!currentUser.uid) return false;

    if (currentUser.chatRooms){
        var chatRooms= currentUser.chatRooms.filter(chatRoom => chatRoom.id !== chatRoomId);
        await updateFirebaseUser(currentUser, {chatRooms});
    }

    var room = await getChatRoomDocument(chatRoomId);
    if (!room) return false;

    if (room.type==="Pod Chat"){
        const pod = await getObjectDocument("pods", room.podID);
        if (!pod) return;
        if (pod.chatRooms){
            var chatRooms= pod.chatRooms.filter(id => id !== chatRoomId);
            await updateFirebasePod(pod, {chatRooms});
        }
    }
    else if (room.type==="User Chat"){
        const user = await getUserDocument(room.userID);
        if (!user) return;
        if (user.chatRooms){
            var chatRooms= user.chatRooms.filter(id => id !== chatRoomId);
            await updateFirebaseUser(user, {chatRooms});
        }
    }

    return true;
}


export const removeUserFile= async (user, folder, fileName)=>{
    if (!user || !folder || !fileName) return false;
    if (!user.uid) return false;

    var file= storage.ref().child("userFiles"+'/'+user.uid+'/'+fileName);
    var success= await file.delete().then(()=>{return true;}).catch(err=>{return false;});

    return success;
}


export const removeTopic = async (post, topicId)=>{
    if (!post || !topicId) return false;
    if (!post.id) return false;


    const topic = await getObjectDocument("topics", topicId);
    if (!topic) return false;
    if (topic.posts){
        var posts= topic.posts.filter(id => id != post.id);
        await updateFirebaseObject("topics",topic.id, {posts});
    }

    return true;
}




export const deletePostById= async (postObject)=>{
    console.log("delete",postObject);

    var currentUser= await getUserDocument(postObject.createdByID);
    console.log(currentUser);
    if (!currentUser || !postObject) return false;
    if (!currentUser.uid || !postObject.id) return false;

    if (currentUser.createdPostIds){
        var createdPostIds= currentUser.createdPostIds.filter(id => id !== postObject.id);
        await updateFirebaseUser(currentUser, {createdPostIds});
    }

    if (postObject.comments){
        for (var i=0; i<postObject.comments.length; i++){
            const comment= postObject.comments[i];
            var user = await getUserDocument( comment.user);
            if (user.createdPostIds){
                var commentedPostIds= user.commentedPostIds.filter(id => id !== postObject.id);
                await updateFirebaseUser(user, {commentedPostIds});
            }
        }
    } 

    if (postObject.interested){
        for (var i=0; i<postObject.interested.length; i++){
            const interested= postObject.interested[i];
            var user = await getUserDocument( interested.user);
            var interestedPostIds = user.interestedPostIds.filter(id => id !== postObject.id);
            await updateFirebaseUser(user, {interestedPostIds});
        }
    }

    if (postObject.savedBy){
        for (var i=0; i<postObject.savedBy.length; i++){
            const savedBy= postObject.savedBy[i];
            var user = await getUserDocument(savedBy);
            var savedPostIds = user.savedPostIds.filter(id => id !== postObject.id);
            await updateFirebaseUser(user, {savedPostIds});
        }

    }

    if (postObject.topics){
        for (var i=0; i<postObject.topics.length; i++){
            var topicId= postObject.topics[i].id;
            await removeTopic(postObject, topicId);
        }
    }


    await deleteObjectDocument('posts', postObject.id);

    return true;

}