import React from 'react';

const Footer = () => {

    return (
        <>
            <div className="footer" >
                <p className="company-name-footer">Hatch</p>
                <div className="footer-row">
                    <p className="terms">Terms</p>
                    <p className="privacy-policy">Privacy Policy</p>
                </div>
            </div>

        </>

    )
}

export default Footer;
