import React, { useEffect, useState } from 'react'
import '../css/ProfileComponents.css';
import { Row, Col } from 'react-bootstrap';
import {GiGolfTee} from 'react-icons/gi';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getAllObjectsNoLimit } from '../../../firebase';
import {addInterests} from '../../../Components/js/GetUserData'
import editIcon from '../../../Images/editIcon.png';
import {IoTrashOutline } from 'react-icons/io5'
import {removeInterest} from '../../../Components/js/DeleteFunctions';



//const options=[{ id:'id',value: 'golf', label: 'golf' }];

export default function AskMeAbout(props) {

    const getFirstHalf= (interestsHalf)=>{
        var temp=[];
        for (var i=0; i<Math.ceil(interestsHalf.length/2); i++){
            temp.push(interestsHalf[i]);
        }
        return temp;
    }

    const getSecondHalf= (interestsHalf)=>{
        var temp=[];
        for (var i=Math.ceil(interestsHalf.length/2); i<interestsHalf.length; i++){
            temp.push(interestsHalf[i]);
        }
        return temp;
    }

    const [interests, setInterests] = useState([]);
    const [options, setOptions]= useState([]);
    const [pull, setPull] = useState(false);
    const [userInterest, setUserInterest] = useState([]);
    const [edit, setEdit] = useState(false);

    

    useEffect(async () => {
        var optionsTemp= await getAllObjectsNoLimit("interests");
        if (props.currentUser.interests){
            for (var i=0; i<props.currentUser.interests.length; i++){
                optionsTemp=optionsTemp.filter(opt=> opt.id !== props.currentUser.interests[i].id);
            }
        }
        setOptions(optionsTemp);
        if (props.currentUser.interests){
            setUserInterest(props.currentUser.interests);}
    }, [pull]);

    const handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        setInterests(newValue);
      };
      const submitInterest= async ()=>{
        console.log("submit", interests);
        var temp=interests;
        if (props.currentUser.interests){
            for (var i=0; i<props.currentUser.interests.length; i++){
                temp=temp.filter(opt=> opt.value !== props.currentUser.interests[i].value);
            }
        }
        if (temp.length >0){
            await addInterests(props.currentUser, temp);}
        setInterests([]);
        var temporary=userInterest;
        temporary.concat(temp);
        setUserInterest(temporary);

        var optionsTemp= options;
        for (var i=0; i<temporary.length; i++){
            optionsTemp=optionsTemp.filter(opt=> opt.id !== temporary[i].id);
        }
        setOptions(optionsTemp);
      }



      const handleDelete= async (id)=>{
            var temp = true;
            if (props.currentUser && id){
                temp= await removeInterest(props.currentUser, id);
            }

            if (temp){
                var temporary=userInterest.filter(interest=> interest.id !== id);
                setUserInterest(temporary);
            }
      }

    return (
        <div className="passions-section">
          <div className="about-header">
            <div className="about-title" ><h4>Passions</h4></div>

            <div style={{textAlign: "right", paddingRight: "4%"}}>
                {props.me ? 
                    <button onClick={() => {setEdit(!edit)}} style={{padding: '2%'}} className="edit-about-me">
                        {!edit ?
                            <img src={editIcon} />
                            :
                            <p className="about-edit-cancel">Cancel</p>
                        }
                    </button>
                    : ''}
            </div>
          </div>              
          {edit ?
            <CreatableSelect 
                id="select-interest"
                isMulti
                value={interests}
                onChange={handleChange}
                options={options}
            />: ''}
            {edit ? <button className="add-skill-button" 
                onClick={submitInterest}>Add Passion</button>: 
            ''}
                <Row style={{paddingLeft: '1%'}}>
                    <Col>
                    {getFirstHalf(userInterest) ? getFirstHalf(userInterest).map((interest, key)=> {
                        return(
                        <div className="skill-row">
                            <div className="skill-text"> 
                                <p style={{marginBottom: '0%'}} key={key}>{interest.value}</p> 
                            </div>
                            {edit ?
                            <div className="trash-icon" >
                                <button onClick={()=>handleDelete(interest.id)} className="delete-icon-button" >
                                    <IoTrashOutline style={{color: 'red'}} />
                                </button>
                            </div>
                            : '' }
                        </div>
                        ); })
                    :''}
                    </Col>
                    <Col>
                    {getSecondHalf(userInterest) ? getSecondHalf(userInterest).map((interest, key)=> {
                        return(
                        <div  className="skill-row">
                            <div className="skill-text" > 
                                <p style={{marginBottom: '0%'}} key={key}>{interest.value}</p> 
                            </div>
                            {edit ?
                            <div className="trash-icon" >
                                <button onClick={()=>handleDelete(interest.id)} className="delete-icon-button">
                                    <IoTrashOutline style={{color: 'red'}}/>
                                </button>
                            </div>
                            : '' }
                        </div>
                        );})
                    :''}
                    </Col>
                </Row>
        </div>
    )
}
