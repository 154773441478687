import { getUserDocument, getChatRoomDocument, updateFirebasePod, updateFirebaseUser, addFriend, getAllObjects, getAllUsers, generateObjectDocument, getObjectDocument, updateFirebaseObject, deleteObjectDocument, getPodDocument } from "../../firebase"

export const getFriends = async (user) => {
    const friends=user.friends
    var friendJson = []
    for (var i = 0; i < friends.length; i++) {
        var friend = await getUserDocument(friends[i]);
        friendJson.push({
            ...friend
        });
    }
    return friendJson;
}

export const getUsersByIDs = async (IDs) => {
    const friends=IDs;
    var friendJson = []
    for (var i = 0; i < friends.length; i++) {
        var friend = await getUserDocument(friends[i]);
        friendJson.push({
            ...friend
        });
    }
    return friendJson;
}

export const getChatRooms = async (user) => {
    const rooms=user.chatRooms
    var friendJson = []
    for (var i = 0; i < rooms.length; i++) {
        var friend = await getChatRoomDocument(rooms[i]);
        friendJson.push({
            ...friend
        });
    }
    return friendJson;
}


export const addRegUserToPod = async (user, pod) => {
    if (!pod || !user) {console.log("undef"); return false;}
    
    pod.regularUsers.push(user.uid);
    await updateFirebasePod(pod, {regularUsers:pod.regularUsers});
    user.pods.push(pod.uid);
    await updateFirebaseUser(user, {pods:user.pods});
    return true;
}

export const addAdminUserToPod = async (user, pod) => {

    if (!pod || !user) {console.log("undef"); return false;}
    if (!pod.id || !user.uid) {console.log("undef"); return false;}

    console.log("user", user);
    console.log("pod",pod);

    if (!pod.admins){
        pod.admins = [];
    }
    if (!user.pods) {
        user.pods=[];
    }

    if (pod.admins.includes(user.uid)) { 
        console.log("already an admin users");
        return false;
    }
    if (user.pods.includes(pod.id)) {
        user.pods=user.pods.filter( podID => podID !== pod.id);
    }

    pod.admins.push(user.uid);
    await updateFirebasePod(pod, {admins:pod.admins});
    user.pods.push(pod.id);
    await updateFirebaseUser(user, {pods:user.pods});
    return true;
}


export const sendFriendRequest = async (user, friendID) => {

    //error checking and get other user
    if (user.uid === friendID) {console.log("cannot send friend request to yourself"); return false;}
    if (!user || !friendID) {console.log("undef"); return false;}
    const otherUser=await getUserDocument(friendID);
    if (!otherUser) {console.log("undef"); return false;}


    //check if already friends
    if (user.friends){
        if (user.friends.includes(friendID)) {console.log("already friend"); return false;}
    }

    if (otherUser.friends){
        if (otherUser.friends.includes(user.uid)) {console.log(" already friend"); return false;}
    }


    //check if request already exists
    if (user.friendRequestsSent){
        if (user.friendRequestsSent.includes(friendID)) {console.log("request already exists"); return false;}
    }

    if (otherUser.friendRequests){
        if (otherUser.friendRequests.includes(user.uid)) {console.log("request already exists"); return false;}
    }

    //add to user's friend request list
    if (!otherUser.friendRequests){
        otherUser.friendRequests = [];
        otherUser.friendRequests.push(user.uid);
    }
    else {
        otherUser.friendRequests.push(user.uid);
    }

    await updateFirebaseUser(otherUser, {friendRequests:otherUser.friendRequests});

    if (!user.FriendRequestsSent){
        user.friendRequestsSent = [];
        user.friendRequestsSent.push(otherUser.uid);
    }
    else {
        user.friendRequestsSent.push(otherUser.uid);
    }

    await updateFirebaseUser(user, {friendRequestsSent:user.friendRequestsSent});
    return true;



}


export const acceptFriendRequest = async (user, friendID) => {

    if (!user || !friendID) {console.log("current user undef"); return false;}

    if (!user.friendRequests) return console.log("no friend request");

    if (!user.friendRequests.includes(friendID)) return console.log("not in friend requests");

    const otherUser=await getUserDocument(friendID);

    if (!otherUser) return console.log("other user undef");

    if (!otherUser.friendRequestsSent) return console.log("no friend request Sent");

    await addFriend(user, friendID);

    user.friendRequests=user.friendRequests.filter(id => id !== friendID);

    otherUser.friendRequestsSent=otherUser.friendRequestsSent.filter(id => id !== user.uid);

    await updateFirebaseUser(user, {friendRequests:user.friendRequests});

    await updateFirebaseUser(otherUser, {friendRequestsSent:otherUser.friendRequestsSent});
    return true;

}


//graph like algorithm for recommended users (recommend friends of friends)
export const getRecommendedFriendsAlgo = async (currentUser, limit) => {

    //search friends first
    var toSearchIds=currentUser.friends;
    var recommend=[];
    var searchedUsers=[];

    while (toSearchIds.length > 0 ){
        var toSearchObjs=await getUsersByIDs(toSearchIds);

        console.log("check here matt",toSearchObjs);
        //iterate over the to search
        for (var i =0; i<toSearchObjs.length; i++){

            //add to searched user
            searchedUsers.push(toSearchObjs[i].uid);

            //find friends of friend that you arent friends with
            var friendsOfFriend=toSearchObjs[i].friends;
            var notFriends= friendsOfFriend.filter(id => !currentUser.friends.includes(id));
            notFriends= notFriends.filter(id => !recommend.includes(id));
            notFriends= notFriends.filter(id => currentUser.uid != id);
            
            if (notFriends){
            recommend=recommend.concat(notFriends);}

            console.log("rec",recommend);

            //break if hit limit wanted
            if (recommend.length >= limit){
                return (await getUsersByIDs(recommend));
            }

            //add the friends of friends to search if not searched
            var moreSearch= friendsOfFriend.filter(id => !searchedUsers.includes(id));
            moreSearch= friendsOfFriend.filter(id => !toSearchIds.includes(id));
            toSearchIds.concat(moreSearch);
        }

        //get rid of searched users in to search
        toSearchIds=toSearchIds.filter(id=>!searchedUsers.includes(id));
    }


    //fill rest of users 
    console.log("one", recommend);
    const fillRest= await getAllObjects("users", 100);
    var notFriend= fillRest.filter(user => !currentUser.friends.includes(user.id));
    notFriend =notFriend.filter(user => !recommend.includes(user.id));
    recommend= await getUsersByIDs(recommend);
    recommend= recommend.concat(notFriend);
    recommend=recommend.filter(user => currentUser.uid != user.id);
    console.log("two", recommend);

    return recommend;
}



//add interests to user and if not an option then add to firebase
export const addInterests=async (currentUser, values)=>{
    if (!currentUser || !values) return console.log("no input");
    if (!currentUser.uid || !values.length >0) return console.log("empty");

    var interests=[];
    if (currentUser.interests){
        interests=currentUser.interests
    }
    for (var i=0; i<values.length; i++){
        var val= values[i];
        var interest={};
        var value=val.value;
        var label=val.label;
        var users=[];
        if (val.__isNew__){
            interest= await generateObjectDocument("interests", {value, label,users});
        }
        else{
            interest=await getObjectDocument("interests", val.id);
        }
        users=interest.users;
        users.push(currentUser.uid);
        updateFirebaseObject("interests",interest.id, {users});
        var interestTemp={id:interest.id, label: interest.label, value: interest.value};
        interests.push(interestTemp);
    }
    
    await updateFirebaseUser(currentUser, {interests});

}

export const addSkills=async (currentUser, values)=>{
    if (!currentUser || !values) return console.log("no input");
    if (!currentUser.uid || !values.length >0) return console.log("empty");

    var skills=[];
    if (currentUser.skills){
        skills=currentUser.skills
    }
    for (var i=0; i<values.length; i++){
        var val= values[i];
        var skill={};
        var value=val.value;
        var label=val.label;
        var users=[];
        if (val.__isNew__){
            skill= await generateObjectDocument("skills", {value, label,users});
        }
        else{
            skill=await getObjectDocument("skills", val.id);
        }
        users=skill.users;
        users.push(currentUser.uid);
        updateFirebaseObject("skills",skill.id, {users});
        var skillTemp={id:skill.id, label: skill.label, value: skill.value};
        skills.push(skillTemp);
    }
    
    await updateFirebaseUser(currentUser, {skills});

}



export const addCollege=async (currentUser, object)=>{

    if (!currentUser || !object) return console.log("no input");
    if (!currentUser.uid || !object.value) return console.log("empty");

    if (currentUser.collegeId){
        var college=await getObjectDocument("colleges", currentUser.collegeId);
        var users=college.users.filter(id => id !== currentUser.uid);
        updateFirebaseObject("colleges",college.id, {users});
    }

    var college={};
    var users=[];
    if (object.__isNew__){
        var value=object.value;
        var label=object.label;
        college= await generateObjectDocument("colleges", {value, label,users});
    }
    else{
        college=await getObjectDocument("colleges", object.id);
    }


    if (college.users){
        users=college.users.filter(id => id != currentUser.uid);
    }
    users.push(currentUser.uid);
    updateFirebaseObject("colleges",college.id, {users});
    var collegeId=college.id;
    
    await updateFirebaseUser(currentUser, {collegeId});

    return true;


}

export const addRole=async (currentUser, object)=>{

    if (!currentUser || !object) return console.log("no input");
    if (!currentUser.uid || !object.value) return console.log("empty");

    if (currentUser.roleId){
        var role=await getObjectDocument("roles", currentUser.roleId);
        var users=role.users.filter(id => id !== currentUser.uid);
        updateFirebaseObject("roles",role.id, {users});
    }

    var role={};
    var users=[];
    if (object.__isNew__){
        var value=object.value;
        var label=object.label;
        role= await generateObjectDocument("roles", {value, label,users});
    }
    else{
        role=await getObjectDocument("roles", object.id);
    }

    if (role.users){
        users=role.users.filter(id => id != currentUser.uid);
    }
    users.push(currentUser.uid);
    updateFirebaseObject("roles",role.id, {users});
    var roleId=role.id;
    
    await updateFirebaseUser(currentUser, {roleId});

    return true;


}



export const addLocation=async (currentUser, object)=>{

    if (!currentUser || !object) return console.log("no input");
    if (!currentUser.uid || !object.value) return console.log("empty");

    if (currentUser.locationId){
        var location=await getObjectDocument("locations", currentUser.locationId);
        var users=location.users.filter(id => id !== currentUser.uid);
        updateFirebaseObject("locations",location.id, {users});
    }

    var location={};
    var users=[];
    if (object.__isNew__){
        var value=object.value;
        var label=object.label;
        location= await generateObjectDocument("locations", {value, label,users});
    }
    else{
        location=await getObjectDocument("locations", object.id);
    }

    if (location.users){
        users=location.users.filter(id => id != currentUser.uid);
    }
    users.push(currentUser.uid);
    updateFirebaseObject("locations",location.id, {users});
    var locationId=location.id;
    
    await updateFirebaseUser(currentUser, {locationId});

    return true;


}


export const addComment=async (currentUser, postObject, message)=>{
    if (!currentUser || !postObject || !message) return false;
    if (!currentUser.uid || !postObject.id) return false;

    var comments=[];
    var comment={user: currentUser.uid, message: message};
    var commentedPostIds=[];
    if (postObject.comments){
        comments=postObject.comments;
    }
    if (currentUser.commentedPostIds){
        commentedPostIds=currentUser.commentedPostIds;
    }
    comments.push(comment);
    commentedPostIds.push(postObject.id);
    commentedPostIds=[...new Set(commentedPostIds)];

    await updateFirebaseObject("posts",postObject.id, {comments});
    await updateFirebaseUser(currentUser, {commentedPostIds});

    return true;
}


export const sendIntrested=async (currentUser, post, message)=>{

    if (!currentUser || !post || !message) return false;
    if (!currentUser.uid || !post.id) return false;

   var interested=[];
    var interest={user: currentUser.uid, message: message};
    var interestedPostIds=[];
    if (post.interested){
        interested=post.interested;
    }
    if (currentUser.interestedPostIds){
        interestedPostIds=currentUser.interestedPostIds;
    }
    interested.push(interest);
    interestedPostIds.push(post.id);
    interestedPostIds=[...new Set(interestedPostIds)];

    await updateFirebaseObject("posts",post.id, {interested});
    await updateFirebaseUser(currentUser, {interestedPostIds});

    return true;
}

export const savePost=async (currentUser, post)=>{
    if (!currentUser || !post) return false;
    if (!currentUser.uid || !post.id) return false;

    var savedPostIds=[];
    if (currentUser.savedPostIds){
        savedPostIds=currentUser.savedPostIds;
    }
    savedPostIds.push(post.id);
    savedPostIds=[...new Set(savedPostIds)];

    var savedBy=[];
    if (post.savedBy){
        savedBy=post.savedBy;
    }
    savedBy.push(currentUser.uid);

    await updateFirebaseUser(currentUser, {savedPostIds});
    await updateFirebaseObject("posts",post.id, {savedBy});

    return true;
} 




export const addTopics=async (post, values)=>{
    if (!post || !values) return console.log("no input");
    if (!post.id || !values.length > 0) return console.log("empty");

    var topics=[];
    if (post.topics){
        topics=post.topics
    }
    for (var i=0; i<values.length; i++){
        var val= values[i];
        var topic={};
        var value=val.value;
        var label=val.label;
        var posts=[];
        if (val.__isNew__){
            topic = await generateObjectDocument("topics", {value, label,posts});
        }
        else{
            topic=await getObjectDocument("topics", val.id);
        }
        posts=topic.posts;
        posts.push(post.id);
        updateFirebaseObject("topics",topic.id, {posts});
        var topicTemp={id:topic.id, label: topic.label, value: topic.value};
        topics.push(topicTemp);
    }
    
    await updateFirebaseObject("posts",post.id, {topics});

    return true;

}



export const sendPodInvite = async (user, podId) => {

    //error checking and get other user
    if (!user || !podId) {console.log("undef"); return false;}
    if (!user.uid) return false;
    if (user.podInvites){
        if (user.podInvites.includes(podId)) {console.log("already Invited"); return false;}
    }

    var pod=await getPodDocument(podId);

    if (!pod || !pod.id) {console.log("undef pod"); return false;}


    //check if already invited
    if (pod.invitedUsers){
        if (pod.invitedUsers.includes(user.uid)) {console.log("already invited"); return false;}
    }


    
    //add to user's pod invite list
    if (!user.podInvites){
        user.podInvites = [];
        user.podInvites.push(pod.id);
    }
    else {
        user.podInvites.push(pod.id);
    }

    //add to pod invited list
    if (!pod.invitedUsers){
        pod.invitedUsers = [];
        pod.invitedUsers.push(user.uid);
    }
    else {
        pod.invitedUsers.push(user.uid);
    }

    await updateFirebaseUser(user, {podInvites: user.podInvites});
    await updateFirebasePod(pod, {invitedUsers: pod.invitedUsers});
   
    return true;


}

export const acceptPodInvite = async (user, podId) => {

    if (!user || !podId) {console.log("undef input"); return false;}
    if (!user.uid) {return false;}

    if (user.pods){
        if (user.pods.includes(podId)){console.log("already have pod");return false;}
    }



    var pod = await getPodDocument(podId);

    //check if pod exists
    if (!pod || !pod.id){
        console.log("undef pod");
        return false;
    }

    //check if user isnt invited
    if (user.podInvites){
        if (!user.podInvites.includes(podId)) { 
            console.log("no invite"); 

            if (pod.invitedUsers){
                if (pod.invitedUsers.includes(user.uid)) 
                {
                    var invitedUsers= pod.invitedUsers.filter(id => id != user.uid);
                    await updateFirebasePod(pod, {invitedUsers});
                }
            }
            return false;
        }
    }

    //check if pod has invite too

    if (pod.invitedUsers){
        if (!pod.invitedUsers.includes(user.uid)) { 
            console.log("no invite"); 

            if (user.podInvites){
                if (user.podInvites.includes(podId)) 
                {
                    var podInvites= user.podInvites.filter(id => id != podId);
                    await updateFirebaseUser(user, {podInvites});
                }
            }
            return false;
        }
    }


    //add to user's pod list
    return (await addAdminUserToPod(user, pod));
    
}