import React, { useState, useEffect }  from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import {getPodDocument, generateObjectDocument, updateFirebaseUser, getAllObjectsNoLimit} from "../../../firebase";
import '../css/FeedComponents.css';
import firebase from "firebase/app";
import CreatableSelect from 'react-select/creatable';
import { addTopics } from '../../../Components/js/GetUserData';


export default function CreatePost({currentUser, repull}) {


    //globals
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [topics, setTopics] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pods, setPods] = useState([]);



    //create a post
    const handleSubmit = async () => {
      var podID = document.getElementById("select-pod").value;
      if (!podID) podID = "";
      const description = document.getElementById("text-post").value;
      const type="regular post";
      const numberOfLikes=0;
      const numberOfDislikes=0;
      const comments=[];
      const createdByID=currentUser.uid;
      
      var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + '/' + dd + '/' + yyyy;
        const createdAt=today;
        const createdTime = firebase.firestore.FieldValue.serverTimestamp();

      handleClose();
      if (description.length > 0) {
        console.log(podID, description);
        const post=await generateObjectDocument('posts',{description, comments, numberOfDislikes, numberOfLikes, type, createdByID, podID, createdAt, createdTime})
        .then((post) => {repull(); return (post)});
        
        await addTopics(post, topics);


        var createdPostIds=[];
        if (currentUser.createdPostIds){
          createdPostIds=currentUser.createdPostIds;
        }
        createdPostIds.push(post.id);
        updateFirebaseUser(currentUser, {createdPostIds});


      }
      

    }
    

    //init globals
    useEffect(async () => {


     if (currentUser.pods) {
       if (currentUser.pods.length > 0) {
        await setPods([]);
        await currentUser.pods.map(async podId => {
          const pod=await getPodDocument(podId);
          var temp=pods;
          temp.push(pod);
          setPods(temp);
        });
     }}

     setOptions(await getAllObjectsNoLimit('topics'));
     
    }, []);


    //handle change for select
    const handleChange = (newValue, actionMeta) => {
      console.group('Value Changed');
      console.log(newValue);
      setTopics(newValue);
    };




    //console.log("create post pods",pods);
  return (
    <div>
      <button className="add-post-button" onClick={handleShow}>
        Create a Post
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{background: '#fbfaf5'}} >
          <Modal.Title>Create a Post</Modal.Title>
          <closeButton />
        </Modal.Header>
        <Modal.Body style={{background: '#fbfaf5'}}>
            <p className="optional-label">Optional: Post for your pod!</p>
            <select id="select-pod" className="select-pod">
                <option value="">Not Associated With a Pod</option>
                {pods && pods.length>0 ? pods.map(pod => <option key={pod.id} value={pod.id}>{pod.name}</option>): null}
            </select>
            <textarea 
                id="text-post"
                rows="5"
                cols="40" 
                placeholder="Write your post"  
                className="create-post-input" 
                required
            />
            <p className="optional-label">Topics (optional)</p>
            <CreatableSelect
                id="select-topic"
                isMulti
                value={topics}
                onChange={handleChange}
                options={options}
            />
        </Modal.Body>
        <Modal.Footer style={{background: '#fbfaf5'}}>
            <button className="add-post-button" onClick={handleSubmit}>
            Post
            </button>
        </Modal.Footer>
      </Modal>
    </div>
    );
}
    

